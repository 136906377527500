<template>
  <div class="home">
    <!-- 核销码 -->
    <div class="search">
      <van-search v-model="code" maxlength="20" show-action placeholder="核销码">
        <template #action>
          <div class="write-off" @click="seerchCode">核销</div>
        </template>
      </van-search>
    </div>

    <!-- 列表 -->
    <div class="content">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" :finished-text="finisheedText" @load="onLoad" offset="20">
          <div v-for="item in list" :key="item.i" class="item">
            <div class="top">
              <div>
                <span class="name">{{ item.n }}</span>
                <span v-if="item.s === 1" class="iconfont icon-a-icon_nan3x" style="color: #1B88FF;"></span>
                <span v-if="item.s === 2" class="iconfont icon-a-icon_nv3x" style="color: #FF1B61;"></span>
              </div>
              <div class="quantity">检测项目量：{{ item.ps.length }}</div>
            </div>
            <div class="time">{{ item.mt }}</div>
          </div>
        </van-list>
        <div style="height: 50px;"></div>
      </van-pull-refresh>
    </div>

    <!-- tabbar -->
    <van-tabbar route active-color="#F86D0C" inactive-color="#999999">
      <van-tabbar-item replace to="/home">
        <template #icon="props">
          <svg class="icon" aria-hidden="true">
            <use :xlink:href="props.active ? '#icon-a-icon_wode13x' : '#icon-a-icon_wode23x'"></use>
          </svg>
        </template>
        待办
      </van-tabbar-item>
      <van-tabbar-item @click="scanning">
        <template #icon="props">
          <svg class="icon" aria-hidden="true">
            <use :xlink:href="props.active ? '#icon-a-icon_saoma13x' : '#icon-a-icon_saoma23x'"></use>
          </svg>
        </template>
        扫码核销
      </van-tabbar-item>
      <van-tabbar-item replace to="/mine">
        <template #icon="props">
          <svg class="icon" aria-hidden="true">
            <use :xlink:href="props.active ? '#icon-a-icon_daiban13x' : '#icon-a-icon_daiban23x'"></use>
          </svg>
        </template>
        我的
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import apiService from "../request/api";
import { Toast } from 'vant';
import { ChangePageTitle } from "@/util/title";
import wx from 'weixin-js-sdk'
import { isWeixinEnv, wxInitConfig } from "../util/wx";

export default {
  name: "home",
  data() {
    return {
      code: '', // 核销码
      list: [],
      loading: false,
      finisheedText: '暂无数据',
      refreshing: false,
      finished: false,
    };
  },
  // 设置浏览器标题
  async created() {
    ChangePageTitle("首页");
    let url = window.location.href.split('#')[0]
    let res = await apiService.getWxConfig(url)
    wxInitConfig(res.data)
  },
  methods: {
    // 进入订单详情
    async seerchCode() {
      if (this.code === '') return
      try {
        let res = await apiService.getNurseDetail(this.code)
        if (res.code === 0) {
          localStorage.setItem('order_detail',JSON.stringify(res.data))
          this.$router.push('/order')
        }
      } catch (error) {
        console.log(error);
      }
    },

    async scanning() {
      let _this = this
      if (isWeixinEnv()) {
        try {
          wx.scanQRCode({
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
            success: async function (res) {
              var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
              _this.writeOffHandle(result)
            },
            fail: function (error) {
              console.log(error);
            }
          })

        } catch (err) {
          console.log(err);
        }
      } else {
        Toast('当前浏览器不支持此操作！请在微信浏览器下处理')
      }
    },

    async writeOffHandle(code) {
      let response = await apiService.getNurseDetail(code)
      if (response.code === 0) {
        localStorage.setItem('order_detail',JSON.stringify(response.data))
        this.$router.push('/order')
      }
    },

    // 获取列表数据
    async getList() {
      try {
        const config = JSON.parse(localStorage.getItem('config'))
        const pop = this.list[this.list.length - 1];
        let params = {
          sc: config.sc,
          ct: pop ? pop.ct : ''
        }
        let res = await apiService.getToDoList(params)
        this.list = this.list.concat(res.data)
        if (res.data.length == 0) {
          this.finished = true
          this.finisheedText = '没有更多了'
        }
        if (this.list.length == 0) {
          this.finisheedText = '暂无数据'
        }
      } catch (error) {
        this.finished = true
        console.log(error);
      }
    },

    async onRefresh() {
      // 清空列表数据
      this.list = []
      this.onLoad() //触发获取事件的方法
    },

    // 上拉加载
    async onLoad() {
      try {
        await this.getList()
        this.loading = false;
        this.refreshing = false;
      } catch {
        this.loading = false;
        this.finished = true;
        this.refreshing = false;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.home {
  background-color: #F7F7F7;
  min-height: 100%;
}

.search {
  width: 375px;
  background: #FFFFFF;
  position: sticky;
  z-index: 99;
  top: 0;

  .van-search__content {
    width: 262px;
    height: 34px;
    background: #FFFFFF;
    border-radius: 22px;
    border: 1px solid #CCCCCC;
  }

  .write-off {
    width: 69px;
    height: 34px;
    background: #F86D0C;
    border-radius: 17px;
    color: #FFFFFF;
    font-size: 14px;
    text-align: center;
    line-height: 34px;
  }
}

.content {
  padding: 12px 16px;
  min-height: 85vh;
  .van-pull-refresh{
    min-height: 85vh;
  }
  .item {
    width: 343px;
    background: #FFFFFF;
    border-radius: 6px;
    padding: 16px;
    box-sizing: border-box;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .name {
        font-size: 16px;
        color: #333333;
        font-weight: 500;
        margin-right: 12px;
      }

      .quantity {
        font-size: 14px;
        color: #333333;
      }
    }

    .time {
      font-size: 12px;
      color: #999999;
      margin-top: 8px;
    }

  }
}

.tips {
  margin: 18px 21px;
  font-size: 12px;
  color: #666666;
}
</style>
