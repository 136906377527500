<template>
  <div class="content">
    <h1 class="title">康圣友医隐私权政策</h1>
    <p class="time">更新日期：2021年11月04日</p>
    <p class="time">生效日期：2021年11月04日</p>
   <div class="container">
      <p class="font-500">【适用范围】康圣友医平台《隐私权政策》（“本政策”）适用于成都温江康圣友医互联网医院有限公司及关联公司通过康圣友医互联网诊疗服务平台（以下简称“康圣友医平台”）向用户（“您”）提供的各项产品或服务（“康圣友医服务”）。如某项康圣友医服务有单独的个人信息处理规则，则该单独规则优先适用，该规则未涵盖的内容，以本政策的内容为准，但本政策不适用于通过接入康圣友医平台的第三方处理您信息的行为。</p>
      <p><span class="font-500">【审慎阅读及签约动作】</span>我们在此提醒您：<span class="font-500">在您注册登录康圣友医平台前，请您务必认真阅读本政策，充分理解各条款内容，特别是免除或限制我们责任的条款以及以粗体标识的条款。您知晓并确认，您自主选择同意（如以勾选、点击“同意”等方式）本政策并使用康圣友医平台，就表示您同意我们按照本政策处理您的个人信息。</span></p>
      <p><span class="font-500">【本政策要点】</span>您可以通过《隐私权政策》了解我们处理用户个人信息的情况，以及您所享有的相关权利。以下为本政策的要点，请仔细阅读：</p>
      <p>1.为向您提供订单核销及数据查询服务，我们将依据《隐私权政策》处理必要的信息。</p>
      <p>2.基于您的明示授权，我们可能会申请开启您设备的定位、相机、网络等权限，您有权拒绝或取消授权。</p>
      <p>3.您可以访问、复制、更正、删除您的个人信息，或在符合法定条件下指定我们向第三方转移您的个人信息，您还可以撤回同意、注销账号、投诉举报以及调整其他隐私设置。</p>
      <p>4.我们已采取符合业界标准的安全防护措施保护您的个人信息。</p>
      <p class="font-500">5.如您是未成年人，请您和您的监护人仔细阅读本政策，并在征得您的监护人授权同意的前提下使用我们的服务或向我们提供个人信息。
        如您想了解更加详细的信息，请阅读以下内容：</p>
      <p class="font-500">一、我们如何收集和使用您的个人信息</p>
      <p class="font-500">二、我们如何保存您的个人信息</p>
      <p class="font-500">三、我们如何使用Cookies和同类技术</p>
      <p class="font-500">四、我们如何转移您的个人信息</p>
      <p class="font-500">五、第三方产品或服务如何获得您的个人信息</p>
      <p class="font-500">六、我们如何保护您的个人信息</p>
      <p class="font-500">七、您如何管理您的个人信息</p>
      <p class="font-500">八、我们如何处理未成年人的个人信息</p>
      <p class="font-500">九、本政策如何更新</p>
      <p class="font-500">十、如何联系我们</p>
      <p class="font-500">一、我们如何收集和使用您的个人信息</p>
      <p>在您使用康圣友医服务时，我们需要/可能需要收集和使用的您的个人信息包括如下两种：</p>
      <p>1、为实现向您提供康圣友医服务的基本功能，您需授权我们收集、使用的必要个人信息。如您拒绝提供相应信息，您将无法正常使用康圣友医服务。</p>
      <p>2、为实现向您提供康圣友医服务的附加功能，您可选择是否授权我们收集、使用的个人信息。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果。</p>
      <p>我们会为实现本政策所述的以下功能，收集和使用您的个人信息：</p>
      <p class="font-500">（一）我们如何收集您的个人信息</p>
      <p>在您使用康圣友医平台过程中，我们会按照如下方式收集您的个人信息：</p>
      <p class="font-500">1.完成注册及完善信息</p>
      <p>当您注册康圣友医平台账户时，您需要提供您的手机号码和验证码。我们收集您的这些信息的法律依据是基于履行与您订立的服务协议所必需，以为您创建账户及提供服务。</p>
      <p>如您使用第三方账户关联登录康圣友医平台的，我们将通过该第三方收集您的注册信息，如您的手机号码、第三方账户、昵称、头像及关联登录页面提示的其他信息。我们收集您上述信息的法律依据是基于您的同意，以为您创建账户及提供服务。</p>
      <p>我们为与我们合作的第三方的会员用户提供自动注册服务，如您授权第三方为您注册康圣友医平台账户，我们将通过第三方收集您的手机号码，以验证您的会员身份，为您创建账户及提供服务。</p>
      <p>如不提供上述注册信息，您仍然可以使用浏览、搜索等公开服务，但无法使用需注册成为康圣友医平台用户方可使用的服务。</p>
      <p>您可以通过提供您的账户头像、昵称、密码和绑定手机号码，以更改或完善您的账户信息。我们收集这类信息的法律依据是基于您的同意，以协助您完善您的账户信息。</p>
      <p class="font-500">2.订单核销服务</p>
      <p>当您开展订单核销业务时，我们需要收集您的姓名及收集号码。我们收集上述信息的法律依据是基于履行与您订立的服务协议所必需。我们收集上述信息的法律依据是基于履行与您订立的服务协议所必需，以供您通过康圣友医平台预约体检项目，及查看体检预约记录和体检报告。如不收集这类信息，我们将无法为您提供体检服务，但不影响您使用其它服务。</p>
      <p class="font-500">3.信息展示和搜索</p>
      <p>为向您展示本地医疗及特色服务，我们需要收集您的地理位置信息。我们收集该信息的法律依据是基于履行与您订立的服务协议所必需，向您展示当地医院、医生和特色服务。如不收集这类信息，我们将无法为您展示您所在地区的本地医疗及特色服务，但不影响您使用其他服务。</p>
      <p>为适配您使用的设备，向您提供信息展示和搜索的最优方式，我们需要收集您的设备信息（包括设备型号、操作系统版本）、<span class="font-500">设备所在位置相关信息（包括IP地址、GPS位置信息）</span>以及日志信息（包括您的搜索查询内容、IP地址访问日期和时间及您访问的网页记录）。</p>
      <p>我们收集这类信息的法律依据是基于履行与您订立的服务协议所必需，向您展示信息和搜索结果。如不收集这类信息，我们将无法提供适应于您设备的信息展示和搜索服务。</p>
      <p>为向您提供更契合您需求的信息展示、搜索及推送服务，我们需要收集您的手机号码、<span class="font-500">浏览及搜索记录设备信息、位置信息、历史问诊、个人生理健康信息、关注医生、</span>加入的圈子以及您补充完善的账户信息（如昵称、头像、联系地址、出生日期）。我们收集上述信息的法律依据是基于您的同意，进行数据分析，以基于特征标签进行间接人群画像，以通过微信平台或手机短信向您展示、推送信息。<span class="font-500">如果您不想希望受到个性化内容的影响或收到商业广告，您可以通过下述方式撤回同意：</span></p>
      <p>1.如果您要删除您的浏览和搜索记录，您可以在点击“搜索框”，并点击“搜索历史”旁边的删除图标；</p>
      <p>2.如您不想接受我们给您发送的商业广告，您可通过手机信息提示回复退订、关闭康圣友医平台的消息提示或通过我们提供的其他方式进行退订或关闭；</p>
      <p>3.如您不希望我们为您展示个性化内容，您可以在“设置-隐私设置”中进行调整。请您知悉，如果您关闭个性化展示功能，您看到的广告数量不会减少，但是广告相关度会降低。</p>
      <p class="font-500">4.客户服务</p>
      <p>当您向我们提出问题、投诉或建议时，我们需要收集您的<span class="font-500">通信/通话记录、</span>您提供的联系方式信息、您为了证明相关事实提供的信息以及您参与问卷调查时向我们发送的问卷答复信息。我们收集上述信息的法律依据是基于履行与您订立的服务协议所必需，为您解决您在使用康圣友医平台及康圣友医服务过程中所遇到的问题，以及向您提供相关问题的处理方案和结果。如不收集这类信息，您的投诉、建议和反馈将无法得到及时、有效处理，但不影响您使用其它服务。</p>
      <p class="font-500">5.保障网络和服务的安全</p>
      <p>为提高您使用我们及我们关联公司、合作伙伴提供的服务的安全性，保护您、其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或康圣友医相关协议规则的情况，我们需要使用或整合您的账户信息、交易信息、设备信息（包括设备型号、操作系统版本、设备设置、Android id、MAC地址、IMSI、唯一设备标识符、已安装应用软件列表、系统中的国别信息、屏幕高度、电量信息、充电信息、蜂窝网类型、设备名称、设备型号、越狱标记、内存大小、系统类型、系统版本、时间戳、代理检测、设备横竖屏（iOS）、模拟器信息（Android）、电池指数（Android）、指示设备是否存在电源（Android）、用户网络标识数据、用户发送验证后产生的生物轨迹）、传感器信息（包括重力传感、陀螺仪传感）、设备所在位置相关信息（包括IP地址、WIFI的SSDI、WIFI的BSSID、WIFI的IP地址、GPS位置）、日志信息以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息。我们收集上述信息的法律依据是基于履行与您订立的服务协议所必需，以综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。如不收集这类信息，您将无法使用康圣友医平台及康圣友医平台提供的相应服务。</p>
      <p class="font-500">（二）设备权限调用情况</p>
      <p>在您使用康圣友医平台过程中，我们可能需要在必要范围内向您申请获取设备权限。<span class="font-500">请您知悉，我们不会默认开启您设备的权限，仅在您主动确认开启的情况下，康圣友医平台才有可能通过设备权限收集您的信息。</span>康圣友医平台调用的权限、调用权限的目的，以及调用权限前向您询问的情况请见下表：</p>
      <img class="table" src="../assets/image/agreement.png" alt="权限信息表">
      <p>如您在首次授权开启权限后希望关闭权限，您可以在设备的设置功能中选择关闭权限，从而拒绝我们收集相应的个人信息。但是，请您知悉，康圣友医平台可能需要开启部分权限并收集必要的个人信息才能得以实现。当您关闭部分设备权限时，我们将无法继续为您提供对应的服务。</p>
      <p class="font-500">（三）我们如何使用您的个人信息</p>
      <p>1.我们会根据本政策的约定并为实现我们的服务目的对所收集的个人信息进行使用。</p>
      <p>2.请您注意，<span class="font-500">您在使用我们提供的服务时所提供的所有个人信息，除非您删除或通过系统设置拒绝我们收集，否则将在您使用我们的服务期间持续授权我们使用</span>。在您注销账号时，我们将停止使用并删除您的个人信息。</p>
      <p>3.我们会对我们提供的服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以用于产品开发、服务优化、安全保障、数据分析等目的。但这些统计信息不包含您的任何身份识别信息。</p>
      <p>4.根据相关法律法规规定，以下情形中收集、使用您的个人信息无需征得您的授权同意：</p>
      <p>（1）为订立、履行您作为一方当事人的合同所必需；</p>
      <p>（2）为履行法定职责或者法定义务所必需；</p>
      <p>（3）为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；</p>
      <p>（4）为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理您的个人信息；</p>
      <p>（5）依照法律规定在合理的范围内处理您自行公开或者其他已经合法公开的个人信息；</p>
      <p>（6）法律、行政法规规定的其他情形。</p>
      <p class="font-500">（四）其他规则</p>
      <p class="font-500">1.您在使用康圣友医服务时，若您提供的信息中含有他人的个人信息，您应在向我们提供这些个人信息之前，您需确保您已经取得合法的授权。若其中涉及儿童个人信息的，您需在发布前取得对应儿童监护人的同意，前述情形下监护人有权通过本政策规定的途径联系我们，要求我们更正或删除涉及儿童个人信息的内容。</p>
      <p class="font-500">2.当我们要将您的个人信息用于本政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意，但具备其他合法事由时依法无需征求您的同意。</p>
      <p class="font-500">二、我们如何保存您的个人信息</p>
      <p>1.我们将在本政策载明的目的所需及法律法规要求的最短保存期限之内，保存您的个人信息。前述期限届满或者您注销康圣友医账户后，我们将对您的个人信息做删除或匿名化处理，但法律法规另有规定或者您另行授权同意的除外。</p>
      <p>2.我们在中国境内运营过程中收集和产生的个人信息将保存在中国境内；如需向境外提供，我们将按照关于个人信息出境的法律要求行事。</p>
      <p class="font-500">三、我们如何使用Cookies和同类技术</p>
      <p class="font-500">1.我们如何使用Cookies</p>
      <p>（1）通过使用Cookies，我们向您提供安全且具个性化的服务体验。我们和第三方合作伙伴会在您的设备上存储Cookies、Flash Cookies，或浏览器（或关联应用程序）提供的其他通常包含标识符、站点名称以及一些号码和字符的本地存储（以上合称“Cookies”）。我们和我们的第三方合作伙伴可能通过Cookies收集您的信息，并将信息用于以下用途：</p>
      <p>安全类Cookies：Cookies可帮助我们保障产品和服务的安全和高效运转。</p>
      <p>推荐类Cookies：Cookies可帮助我们为您推荐、展示、推送您可能感兴趣的内容。</p>
      <p class="font-500">（2）第三方合作伙伴通过Cookies收集和使用您的信息不受本政策约束，而是受到其自身的信息保护声明约束，我们不对第三方的Cookies或同类技术承担责任。</p>
      <p class="font-500">（3）如果您的浏览器或浏览器附加服务允许，您可修改对Cookies的接受程度或拒绝我们的Cookies。有关详情，请参见https://www.aboutcookies.org/。但如果您这么做，在某些情况下可能会影响您安全使用我们的产品或服务。同时，您仍然将收到商品或服务信息，只是这些商品或服务信息与您的相关性会降低。</p>
      <p class="font-500">2.我们如何使用同类技术</p>
      <p>除Cookies外，我们还会在康圣友医平台上使用平台信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至康圣友医平台内容的地址链接，如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好以便于我们主动改善客户服务体验。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。</p>
      <p class="font-500">四、我们如何转移您的个人信息</p>
      <p class="font-500">1.对外提供</p>
      <p class="font-500">（1）基于履行服务协议所必需</p>
      <p class="font-500">部分康圣友医服务可能是我们的关联公司和合作机构（“业务合作机构”）或我们与第三方共同向您提供。因此，为履行与您订立的服务协议，我们必需将您的个人信息提供给我们的关联公司及业务合作伙伴。</p>
      <p class="font-500">（2）如您主动、自愿要求我们向第三方提供您的个人信息的，我们将基于您同意的目的，在相应页面中以适当方式告知您个人信息接收方的名称和联系方式。例如，您主动要求使用康圣友医平台账户登录第三方产品或服务的，我们或第三方将在关联登录页面告知您为此目的，康圣友医需向第三方提供的个人信息以及第三方的名称和联系方式。</p>
      <p class="font-500">（3）我们为履行法定义务而向第三方提供您的个人信息的，我们将在相应页面中以适当方式告知您个人信息接收方的名称和联系信息。</p>
      <p class="font-500">2.为并购重组等原因转移个人信息</p>
      <p>如因合并、分立、解散、被宣告破产等原因需要转移您个人信息，我们将向您告知接收方的名称或者姓名和联系方式，并促使接收方继续履行个人信息保护义务。接收方变更原先的处理目的、处理方式的，应当依法规定重新取得您的同意，或具备其他合法事由。</p>
      <p class="font-500">3.公开披露</p>
      <p>我们原则上不会公开披露您的个人信息，但取得您的单独同意或具备其他合法事由的除外。</p>
      <p class="font-500">4.委托处理</p>
      <p>为了提升信息处理和服务效率和准确性，我们会委托专业机构代表我们来处理您的个人信息，如基础设施技术服务、短信分发服务、实人认证服务、开票服务、视频和外呼SaaS服务、物流服务、检验检测服务等服务提供商。上述委托处理行为受本政策中所声明目的约束，并且我们会通过书面协议、审计等方式要求受托机构要求遵守严格的保密义务及采取有效的保密措施，禁止其将受托处理的信息用于受托目的之外的用途。</p>
      <p class="font-500">五、第三方产品或服务如何获得您的个人信息</p>
      <p>为了向您提供更丰富和完整的功能，我们会使用下述第三方软件开发工具包（简称“SDK”）为您提供服务。SDK的具体信息收集和使用情况请以SDK运营方的隐私政策或相关声明为准。点击《第三方SDK类服务商目录》可查看我们接入的授权合作伙伴的SDK详情。</p>
      <p class="font-500">六、我们如何保护您的个人信息</p>
      <p>1.我们已采取符合业界标准、合理可行的安全防护措施保护您提供的个人信息安全，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，我们会使用加密技术（例如，SSL/TLS）、匿名化处理等手段来保护你的个人数据。目前，我们的重要信息系统已通过信息安全等级保护（三级）测评和备案。</p>
      <p>2.我们已建立专门的管理系统、流程和组织，确保信息安全。例如，我们严格限制有信息访问权限的人员，要求这些人员遵循其保密义务，并对此进行审查。</p>
      <p class="font-500">3.我们将不定期更新并公开安全风险、个人信息安全影响评估报告等有关内容，您可通过康圣友医平台上的公告方式获得。</p>
      <p class="font-500">4.互联网并非绝对安全的环境，我们强烈建议您不要使用非康圣友医推荐的通信方式发送个人信息。请注意，您在使用康圣友医服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至敏感个人信息，如您在发帖、评论时选择上传包含个人信息的图片。请您更加谨慎地考虑，是否在使用康圣友医服务时共享甚至公开分享相关信息。</p>
      <p class="font-500">5.请使用复杂密码，协助我们保证您的账号安全。我们将尽力保障您发送给我们的任何信息的安全性。如您发现自己的个人信息尤其是您的账户或密码发生泄露，请您立即联络客服，以便我们根据您的申请采取相应措施。</p>
      <p class="font-500">6.如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</p>
      <p class="font-500">7.在不幸发生个人信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。</p>
      <p class="font-500">七、您如何管理您的个人信息</p>
      <p>在您使用康圣友医平台期间，您可以通过以下方式访问、管理您的个人信息，以及行使改变授权同意的范围与注销账号的权利，您的意见会及时得到处理。</p>
      <p class="font-500">1.访问和复制您的个人信息</p>
      <p>您有权访问和复制您的个人信息，法律法规规定的例外情况除外。您可以通过以下方式自行访问或复制您的个人信息：</p>
      <p>（1）账户信息：如果您希望访问或编辑您的账户中的个人基本资料信息和实名认证信息、更改您的密码或添加绑定邮箱等，您可以登录账号通过“我-设置-账号设置”执行此类操作。</p>
      <p>（2）常用就诊人（家庭就诊人）信息：如果您希望编辑、删除您的账户中的常用就诊人信息，您可以登录账号通过“我-常用就诊人”执行此类操作。</p>
      <p>（3）预约、问诊、购药记录：您可以登录账号，通过“我”查看了解历史记录。</p>
      <p>如果您无法通过上述路径访问或复制该等个人信息，您可以随时通过客服中心与我们取得联系。我们将在15个工作日内回复您的访问请求。</p>
      <p>对于您在使用康圣友医的服务或产品过程中产生的其他个人信息，只要我们不需要过多投入，我们将根据本条“响应您的上述请求”中的相关安排向您提供。</p>
      <p class="font-500">2.更正您的个人信息</p>
      <p>当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正。您可以通过本部分“访问您的个人信息”中列明的方式提出更正申请。</p>
      <p class="font-500">3.删除您的个人信息</p>
      <p>您可以通过本部分“访问您的个人信息”中列明的方式删除您的部分个人信息。</p>
      <p>在以下情形中，您可以通过本政策载明的方式向我们提出删除个人信息的请求：</p>
      <p class="font-500">（1）个人信息处理目的已实现、无法实现或者为实现处理目的不再必要；</p>
      <p class="font-500">（2）我们停止提供产品或者服务，或者保存期限已届满；</p>
      <p class="font-500">（3）您撤回同意；</p>
      <p class="font-500">（4）我们违反法律、行政法规或者违反约定处理个人信息；</p>
      <p class="font-500">（5）法律、行政法规规定的其他情形。</p>
      <p class="font-500">请您知悉，因法律、行政法规规定的保存期限未届满，或者删除个人信息从技术上难以实现的，在您请求删除个人信息后，我们可能不会立即删除您的信息，但我们将停止除存储和采取必要的安全保护措施之外的处理。</p>
      <p class="font-500">4.改变您授权同意的范围</p>
      <p>您可以通过删除信息，关闭设备功能以及在康圣友医平台上进行隐私设置等方式改变您授权我们继续收集个人信息的范围或撤回您的授权。</p>
      <p class="font-500">请您理解，康圣友医平台可能需要开启部分权限并收集必要的个人信息才能得以实现。当您撤回您的同意时，我们将无法继续为您提供撤回同意所对应的服务，但不会影响此前基于您的授权而开展的个人信息处理服务。</p>
      <p class="font-500">5.注销账户</p>
      <p>您可通过“我-帮助反馈-我的客服”功能联系客服完成账户的注销，或在“我-设置-账号设置”功能中查看账户注销流程。</p>
      <p>在符合康圣友医相关服务协议约定条件及国家相关法律法规规定的情况下，您的该项康圣友医服务账号可能被注销或删除。<span class="font-500">您注销上述账户的行为是不可逆的，我们将停止为您提供产品或服务，不再收集您的个人信息，并依据您的要求删除与您账户相关的个人信息或做匿名化处理，但法律法规另有规定的除外。请您谨慎操作，否则，因账户注销产生的数据丢失问题均由您自行承担。</span></p>
      <p class="font-500">6.向指定第三方转移个人信息</p>
      <p>您如果需要将您的个人信息转移至您指定的第三方的，您可以通过本政策载明的方式联系我们，在符合法律法规规定的条件下，我们将逐一处理和响应。</p>
      <p class="font-500">7.约束自动决策</p>
      <p>在某些互联网电子服务或产品中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，拒绝我们仅通过自动化决策的方式作出决定，我们也将在不侵害我们和关联公司商业秘密、用户权益、社会公共利益的前提下提供申诉方法。</p>
      <p class="font-500">8.响应您的上述请求</p>
      <p>如您无法按照上述方式行使权利的，您可以采取本政策载明的联系方式与我们联系。为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。</p>
      <p>我们将会在15个工作日内针对您的请求作出答复。如您不满意，可通过客服中心发起投诉。</p>
      <p>对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。</p>
      <p class="font-500">9.特殊情况下对您的近亲属请求的响应</p>
      <p>如您不幸身亡的，您的近亲属为了其自身的合法、正当利益，可以根据本政策载明的联系方式与我们联系，对您的相关个人信息行使本部分规定的访问、复制、更正、删除等权利。在响应您的近亲属行使您的个人信息利益前，我们将通过严格的流程对其身份和行权目的进行审核。如您不同意您的近亲属在您逝世后，可根据本政策载明的联系方式与我们联系予以拒绝我们响应。</p>
      <p class="font-500">10.响应情形的例外</p>
      <p>在以下情形中，我们将无法响应您的请求:</p>
      <p class="font-500">（1）与我们履行法律法规规定的义务相关的;</p>
      <p class="font-500">（2）与国家安全、国防安全直接相关的;</p>
      <p class="font-500">（3）与公共安全、公共卫生、重大公共利益直接相关的;</p>
      <p class="font-500">（4）与刑事侦查、起诉、审判和执行判决等直接相关的;</p>
      <p class="font-500">（5）我们有充分证据表明个人信息主体存在主观恶意或滥用权利的;</p>
      <p class="font-500">（6）响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的;</p>
      <p class="font-500">（7）涉及商业秘密的。</p>
      <p class="font-500">八、我们如何处理未成年人的个人信息</p>
      <p class="font-500">1.我们非常重视对未成年人个人信息的保护。根据相关法律法规的规定，收集、使用未满14周岁的未成年人的个人信息，需由监护人授权同意；收集、使用已满14周岁未满18周岁的未成年人个人信息，可由监护人授权同意或自行授权同意。</p>
      <p class="font-500">2.如您为未成年人（尤其是不满14周岁的未成年人），我们要求您请您的父母或其他监护人仔细阅读本政策，并在征得您的监护人授权同意的前提下使用我们的服务或向我们提供信息。</p>
      <p class="font-500">3.如您是未成年人的监护人，请您关注您所监护的未成年人是否是在您授权同意之后使用我们的产品或服务。如果您对您所监护的未成年人的个人信息有疑问，请通过本政策载明的方式与我们联系。</p>
      <p class="font-500">九、本政策如何更新</p>
      <p>本政策可能会适时发生变更。我们会在本页面上发布对本政策所做的任何变更。对于重大变更，我们还会提供更为显著的通知（我们可能会通过在浏览页面做特别提示等方式，说明本政策的具体变更内容）。</p>
      <p class="font-500">未经您明确同意，我们不会削减您按照本政策所应享有的权利。</p>
      <p>本政策所指的重大变更包括但不限于：我们的服务模式发生重大变化；个人信息共享、转让或公开披露的主要对象发生变化；您参与个人信息处理方面的权利及其行使方式发生重大变化；我们的联系方式及投诉渠道发生变化；个人信息安全影响评估报告表明存在高风险时。</p>
      <p>我们还会将本政策的旧版本存档，供您查阅。</p>
      <p class="font-500">十、如何联系我们</p>
      <p>1.康圣友医平台软件技术服务提供者：成都温江康圣友医互联网医院有限公司。注册地址：成都市温江区光华大道三段1588号1709室（统称“我们”）。</p>
      <p>2.如果您对本政策或个人信息保护有任何问题，您可以将您的书面疑问、意见或建议通过以下方式与我们联系：</p>
      <p>（1）您可以通过康圣友医平台内反馈、电话号码、康圣友医客服中心网站与我们进行联系：</p>
      <p>康圣友医平台内反馈：通过“设置-隐私-联系客服”可获取人工智能回复；</p>
      <p>（2）一般情况下，我们将在十五日内或法律法规规定的期限内尽快向您回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，<span class="font-500">且在您反馈后我们仍拒绝改进的，</span>您还可以向网信、工信、公安及市场监管等监管部门进行投诉或举报，或通过向本协议签订地（成都市温江区）有管辖权的法院提起诉讼来寻求解决方案。</p>
   </div>
  </div>
</template>
<script>
import { ChangePageTitle } from "@/util/title";
export default {
  name: "privacy-policy",
  data() {
    return {
      loading: false,
    };
  },
  created() {
    ChangePageTitle("隐私协议");
  },
};
</script>

<style lang="scss" scoped>
.content{
  padding: 16px;
  font-size: 14px;
  color: #666666;
  line-height: 20px;
  text-align: justify;
  .title{
    font-size: 18px;
    color: #333333;
    text-align: center;
    font-weight: 500;
    margin-bottom: 16px;
  }
  .time{
    text-align: right;
    font-size: 12px;
    color: #666666;
  }
  .container{
    margin-top: 16px;
  }
  .font-500{
    font-weight: 500;
    color: #333333;
  }
  .table{
    width: 100%;
  }
}

</style>
