<template>
  <div class="content">
    <img src="../assets/image/about-1.jpg" alt="">
    <img src="../assets/image/about-2.jpg" alt="">
    <img src="../assets/image/about-3.jpg" alt="">
    <img src="../assets/image/about-4.jpg" alt="">
    <img src="../assets/image/about-5.jpg" alt="">
    <img src="../assets/image/about-6.jpg" alt="">
  </div>
</template>
<script>
import { ChangePageTitle } from "@/util/title";
export default {
  name: "about",
  data() {
    return {
    };
  },
  methods: {
  },
  created() {
    ChangePageTitle("关于我们");
  },
};
</script>

<style lang="scss" scoped>
.display-flex{
  display: flex;
  justify-content: center;
  align-items: center;
}
.content{
  img{
    width: 100%;
  }
}
</style>
