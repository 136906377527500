<template>
  <div class="content">
    <div class="nav">
      <div class="item customer" @click="call">
        <div class="display-flex">客服电话</div>
        <div class="value">4006826661</div>
      </div>
      <div class="item" @click="gotoServicePolicy">
        <div class="display-flex">互联网医院服务协议</div>
        <div class="iconfont icon-a-icon_right3x"></div>
      </div>
      <div class="item" @click="gotoPrivacyPolicy">
        <div class="display-flex">互联网医院隐私协议政策</div>
        <div class="iconfont icon-a-icon_right3x"></div>
      </div>
      <div class="item" @click="gotoAbout">
        <div class="display-flex">关于我们</div>
        <div class="iconfont icon-a-icon_right3x"></div>
      </div>
    </div>
    <van-button class="logout-btn" round block @click="logout">退出登录</van-button>
  </div>
</template>
<script>
import { ChangePageTitle } from "@/util/title";
import apiService from "../request/api";
export default {
  name: "site",
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    // 退出登录
    async logout() {
      let res = await apiService.logout()
      if(res.code === 0){
        localStorage.clear()
        this.$router.replace('/login')
      }
    },

    gotoPrivacyPolicy() {
      this.$router.push('/privacy-policy')
    },
    gotoServicePolicy() {
      this.$router.push('/service-policy')
    },
    gotoAbout() {
      this.$router.push('/about')
    },
    call() {
      window.location.href = 'tel:4006826661';
    }
  },
  created() {
    ChangePageTitle("设置");
  },
};
</script>

<style lang="scss" scoped>
.display-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  background-color: #F7F7F7;
  height: 100%;
  padding-top: 12px;
  box-sizing: border-box;
}

.nav {
  margin: auto;
  width: 343px;
  background-color: #FFFFFF;
  border-radius: 8px;

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 16px;
    padding: 16px 0;
    border-bottom: 1px solid #F7F7F7;
    font-size: 16px;
    color: #333333;

    .value {
      font-size: 16px;
      color: #F86D0C;
    }

    .iconfont {
      font-size: 18px;
      color: #CCCCCC;
    }
  }

  .customer {
    border-bottom: 1px solid transparent;
  }
}

.logout-btn {
  width: 343px;
  height: 44px;
  margin: auto;
  color: #999999;
  position: fixed;
  bottom: 40px;
  right: 0;
  left: 0;
}
</style>
