<template>
  <div class="content">
   <h1 class="title">康圣友医互联网医院用户注册及服务协议</h1>
   <p class="time">最近更新日期：2024年10月01日</p>
   <p>欢迎您与康圣友医服务提供者（以下简称“我们”、“康圣友医”）签署本《成都温江康圣友医互联网医院用户注册及服务协议》（以下简称“本协议”）并使用康圣友医各项服务。本协议对您与康圣友医互联网医院具有同等法律效力。</p>
   <div class="container">
    <p class="font-500 font-18">一、接受条款</p>
    <p>【审慎阅读】您在申请注册流程中点击同意本协议之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款内容，特别是免除或限制责任条款、用户个人信息保护及肖像权授权条款、知识产权及保密条款、法律适用和争议解决条款，以及其他以粗体标识的涉及用户核心利益的重要条款。</p>
    <p>【协议范围】由于互联全符合未来发展的需求。因此，康圣友医公示的以及后续发布的相关声明及政策、规则和协议均为本协议的补充协网高速发展，本协议列明的条款并不能完整罗列并覆盖贵我双方所有权利与义务，现有的约定也不能保证完议，与本协议不可分割且具有同等法律效力。如您使用康圣友医服务，视为您同意上述补充协议。当您与康圣友医发生争议时，应以最新的协议条款为准</p>
    <p>【签约动作】当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容，并与康圣友医达成一致，成为康圣友医“用户”。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序。如果您未申请注册流程，则您通过访问和/或使用康圣友医，即视为您表示同意接受本协议以及其他与康圣友医相关服务相关的协议和规则（包括但不限于康圣友医《隐私权政策》的全部内容的约束），否则请您不要访问或使用康圣友医。</p>
    <p>我们有权依康圣友医相关服务或运营的需要单方决定，安排或指定其关联方、控制公司、继承公司或我们认可的第三方公司继续运营康圣友医。并且，就本协议项下涉及的某些服务，可能会由我们的关联方、控制公司、继承公司或公司认可的第三方公司向您提供。您知晓并同意接受相关服务内容，即视为接受相关权利义务关系亦受本协议约束。</p>
    <p class="font-500 font-18">二、定义</p>
    <p>康圣友医：指我们开发、运营的面向正规医疗机构医生的服务平台，包括康圣友医网站（域名为kindstaryouyi.com、kindstaryouyi.cn、）、 康圣友医客户端和康圣友医公众号等。</p>
    <p>康圣友医服务：指用户可依托成都温江康圣友医互联网医院，基于互联网，为康圣友医平台患者用户提供远程诊疗等医疗服务或健康咨询服务，并可享受医生协作、患者管理、发布医疗科普知识等服务。</p>
    <p>康圣友医平台：指成都温江康圣友医互联网医院开发、运营的互联网诊疗服务平台，包括域名为kindstaryouyi.com；kindstaryouyi.cn；等网站及客户端应用程序。</p>
    <p>关联公司：康圣友医直接或者间接控股的公司，参股或形成经营、协作的，具有关联关系的企业。</p>
    <p>用户：指康圣友医产品、服务的使用人，即医生，在本协议中被称为“您”或“用户”。</p>
    <p>个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。</p>
    <p class="font-500 font-18">三、账号注册与使用</p>
    <p class="font-500">1.用户资格</p>
    <p>您确认，在您开始使用/注册程序使用康圣友医服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。</p>
    <p class="font-500">2.账号获得</p>
    <p>（1）当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，您可获得康圣友医账号并成为康圣友医用户。您有权使用您设置或确认的康圣友医用户名（以下简称“账号名称”）及您设置的密码（账号名称及密码合称“账号”）登录康圣友医。</p>
    <p>（2）我们有权在患者或第三方投诉或有理由相信医生提交的资料存在虚假、失效等情况下采取暂停医生账号的使用等措施，而不需现行通知医生本人。</p>
    <p class="font-500">3.注册信息管理</p>
    <p>（1）真实合法</p>
    <p>在使用康圣友医服务时，您应当按网站页面的提示准确完整地提供您的信息（可能会包括您的姓名、联系电话、联系地址等），以便我们在必要时与您联系。您了解并同意，您有义务保持您提供信息的真实性及有效性，不得以虚假、冒用的居民身份信息等注册信息进行注册或认证，任何非法、不真实、不准确的用户信息所产生的责任由您自行承担。</p>
    <p>您理解并承诺，您的账号名称、头像和简介等注册信息中不得出现违法和不良信息，没有冒用、关联机构或社会名人，您在账号注册过程中需遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等七条底线。否则我们可对您的账号名进行暂停使用或注销等处理，并向主管机关报告。</p>
    <p>如平台发现您在平台中注册或使用的账号等一切自定义名称与其他用户相同而导致无法识别，平台有权要求您修改上述名称，如您在康圣友医平台要求的时限内未予修改，则平台有权在您自定义的名称后加注识别符号予以区别以确保软件正常运行，您保证无条件同意上述修改。</p>
    <p>您同意并授权，为了更好的为您提供服务以及确保您的账号安全，公司可以根据您提供的手机号码等信息，向电信运营商、金融服务机构等可靠单位发起用户征信记录、用户手机号码有效性状态等情况的查询。</p>
    <p>（2）更新维护</p>
    <p>您应当及时更新您提供的信息（包括但不限于注册资料等），确保符合及时、详尽、真实、准确的要求。在法律有明确规定要求公司对部分用户的信息进行核实的情况下，公司将依法不时地对您的信息进行检查核实，您应当配合提供最新、真实、完整的信息。您在更改、删除有关信息的同时也可能会造成储存在系统中的文字、图片、视频等丢失，公司对您自主操作导致内容丢失不承担责任。</p>
    <p>如公司按您最后一次提供的信息与您联系未果、您未按公司的要求及时提供信息、您提供的信息存在明显不实或行政司法机关核实您提供的信息无效的，您将承担因此对您自身、他人及公司造成的全部损失与不利后果。公司可向您发出询问或要求整改的通知，并要求您进行重新认证，直至中止、终止对您提供部分或全部康圣友医平台服务，公司对此不承担责任。</p>
    <p class="font-500 font-18">四、服务使用条件</p>
    <p>1.您使用上述相关服务的，可以通过康圣友医已授权的第三方下载等方式获取康圣友医客户端应用程序或访问康圣友医。若您并非从康圣友医或经康圣友医授权的第三方获取康圣友医平台服务的，康圣友医无法保证非官方版本的平台入口、软件能够正常使用，您因此遭受的损失与康圣友医无关。</p>
    <p>2.康圣友医可能为不同的终端设备开发不同的应用程序软件版本，您应当根据实际设备状况获取、下载、安装合适的版本。</p>
    <p>3.您理解，您使用康圣友医平台相关服务需自行准备与平台及相关服务有关的终端设备（如电脑、手机等装置），一旦您在您终端设备中打开康圣友医软件或访问康圣友医相关链接，即视为您使用康圣友医平台相关服务。为充分实现平台的全部功能，您可能需要将您的终端设备联网，您理解您应自行承担所需要的费用（如流量费、上网费等）。</p>
    <p class="font-500 font-18">五、医生的权利和义务</p>
    <p>（一）医生的权利</p>
    <p>在符合《互联网诊疗管理办法（试行）》和经过平台授权的前提下，您享有以下权利：</p>
    <p>1.您有权对互联网医院在服务过程中的不规范行为提出建议或投诉。</p>
    <p>1.您有权在使用康圣友医、康圣友医平台相关电子商品或服务出现问题时提出反馈，属于平台技术问题的由我们负责解决。</p>
    <p>（二）医生的义务</p>
    <p>1.医生保证提交康圣友医注册审核的各类材料真实、合法、有效。提交的材料失效或被吊销的，医生应及时通知康圣友医，补充提交新的资质证明或暂停服务权限。由医生延迟告知造成的一切纠纷由医生负责解决，由此给康圣友医或患者造成损失的，由医生承担一切赔偿责任。</p>
    <p>2.您应确保未经互联网医院指派，不擅自接受互联网医院合作医疗机构或患者的服务委托，不利用互联网医院、康圣友医平台或康圣友医相关的企业品牌开展非本协议授权或约定范围内的业务</p>
    <p>3.本协议履行期间，您应确保不从事有损您个人执业声誉或有损互联网医院、康圣友医平台及关联公司品牌、声誉的行为。</p>
    <p>4.您使用平台相关服务不得进行任何危害计算机网络安全的行为和/或其他违法违规行为，包括但不限于：</p>
    <p>（1）非法侵入网络、干扰网络正常功能、窃取网络数据等危害网络安全的活动；</p>
    <p>（2）提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络数据等危害网络安全活动的程序、工具；</p>
    <p>（3）明知他人从事危害网络安全的活动的，为其提供技术支持、广告推广、支付结算等帮助；</p>
    <p>（4）使用未经许可的数据或进入未经许可的服务器/账号；</p>
    <p>（5）未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；</p>
    <p>（6）未经许可，企图探查、扫描、测试平台系统或网络的弱点或其它实施破坏网络安全的行为；</p>
    <p>（7）企图干涉、破坏康圣友医平台系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；</p>
    <p>（8）伪造TCP/IP数据包名称或部分名称；</p>
    <p>（9）对平台相关服务进行反向工程、反向汇编、编译或者以其他方式尝试发现平台相关服务的源代码；</p>
    <p>（10）违反法律法规、本协议、平台相关规则及侵犯他人合法权益的其他行为。</p>
    <p>如果康圣友医有理由认为您的行为违反或可能违反上述约定的，康圣友医可独立进行判断并处理，且在任何时候有权在不事先通知的情况下终止向您提供服务，并依法追究相关责任。</p>
    <p>5.您在平台上发布的所有信息/资讯/言论/内容等均不得含有任何违反中华人民共和国政策法规和/或平台要求的内容，包括但不限于：</p>
    <p>（1）危害国家安全、淫秽色情、虚假、违法、诽谤（包括商业诽谤）、非法恐吓或非法骚扰、侵犯他人知识产权、人身权、商业秘密或其他合法权益以及有违公序良俗的内容或指向这些内容的链接；</p>
    <p>（2）泄露患者隐私、健康医疗数据的；</p>
    <p>（3）有明显暗示患者去某医院某科室就诊的广告，或者过分夸大某项医疗技术或某种药物作用；</p>
    <p>（4）过分彰显某公司药品或器材的商品名；</p>
    <p>（5）含有其他广告性质的内容或网站链接；</p>
    <p>（6）其他含有违反法律法规、社会主义制度、国家利益、公民合法权益、社会公共秩序、道德风尚和信息真实性的信息。</p>
    <p class="font-500 font-18">六、医疗纠纷/违约处理</p>
    <p>1．在您提供互联网诊疗或健康咨询服务过程中与患者发生纠纷的，您应立即向互联网医院相应负责机构汇报。如发生医疗事故或可能引起医疗事故的情形的，您应立即向互联网医院相应负责机构报告，由互联网医院统一处理。</p>
    <p>2．您在互联网医院执业过程中发生医疗损害或纠纷的，应当由多互联网医院和您按照有关法律法规处理。对构成医疗事故的，互联网医院负责处理后，根据您是否存在故意或过失行为，有权向您按责任比例追偿损失或赔偿金（有权向您追责和追偿赔偿金）。</p>
    <p>3.因您违反本协议或其他服务条款规定，引起患者或第三方投诉或诉讼（非医疗事故）索赔的，您应当自行处理并承担可能因此产生的全部法律责任。因您的违法或违约等行为导致康圣友医及其关联方、控制公司、继承公司向任何第三方赔偿或遭受国家机关处罚的，您还应足额赔偿康圣友医及其关联方、控制公司、继承公司因此遭受的全部损失，该等损失范围包括但不限于康圣友医自身的直接经济损失、商誉损失，以及康圣友医向第三方支付的索赔额（如有）和对外支付的赔偿金、行政处罚款、和解款、律师费、诉讼费等。</p>
    <p class="font-500 font-18">七、责任限制及免除</p>
    <p>1.您理解并同意，康圣友医平台相关服务可能会受多种因素的影响或干扰，康圣友医不保证(包括但不限于)：</p>
    <p>（1）康圣友医平台相关服务完全适合用户的使用要求；</p>
    <p>（2）康圣友医平台相关服务不受干扰，及时、安全、可靠或不出现错误；用户经由公司取得的任何软件、服务或其他材料符合用户的期望；</p>
    <p>（3）康圣友医平台相关服务中任何错误都将能得到更正。</p>
    <p>2.对于下述原因导致康圣友医无法正常提供服务的，康圣友医不承担任何责任：</p>
    <p>（1）因自然灾害、罢工、暴乱、战争、政府行为、司法行政命令等不可抗力因素；</p>
    <p>（2）因电力供应故障、通讯网络故障等公共服务因素和/或因黑客、病毒、恶意程序攻击等第三人因素；</p>
    <p>（3）在康圣友医已尽善意管理的情况下，因常规或紧急的设备与系统维护、设备与系统故障、网络信息与数据安全等因素；</p>
    <p>（4）在紧急情况下为维护国家安全、其他用户及/或第三方之权益等因素；</p>
    <p>（5）用户操作不当或用户的上网设备软硬件出现故障等非康圣友医因素；</p>
    <p>（6）行政监管部门作出有关限制操作。</p>
    <p>尽管有前款约定，康圣友医将采取合理行动积极促使服务恢复正常。</p>
    <p>3.康圣友医依据本协议约定获得处理违法违规内容的权利，该权利不构成康圣友医的义务或承诺，康圣友医不能保证及时发现违法行为或进行相应处理。</p>
    <p>4.您理解并同意：关于康圣友医平台相关服务，康圣友医不提供任何种类的明示或暗示担保或条件，包括但不限于商业适售性、特定用途适用性等。您对康圣友医平台相关服务的使用行为应自行承担相应风险。</p>
    <p>5.在任何情况下，康圣友医均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害，包括因您使用康圣友医平台相关服务而遭受的利润损失、数据或文档丢失产生的损失、人身伤害等承担责任。除法律法规另有明确规定外，康圣友医对您承担的全部责任，无论因何原因或何种行为方式，始终不超过您因使用康圣友医平台相关服务期间而支付给康圣友医的费用（如有）。</p>
    <p class="font-500 font-18">八、用户个人信息保护及肖像权授权</p>
    <p>1.【个人信息保护】保护用户个人信息是康圣友医的基本原则之一，在使用康圣友医平台相关服务的过程中，您可能需要提供您的个人信息（包括但不限于您的姓名、电话号码、设备信息、身份证等），以便康圣友医向您提供更好的服务和相应的技术支持。康圣友医将依法保护您浏览、修改、删除相关个人信息以及撤回授权的权利，并将运用加密技术、匿名化处理等其他与康圣友医平台相关服务相匹配的技术措施及其他安全措施保护您的个人信息。</p>
    <p>更多关于您个人信息保护的内容，请参看康圣友医《隐私权政策》。</p>
    <p>同时，您知晓并承诺，您应当对在使用康圣友医平台提供服务的过程中知悉的患者个人数据进行保密；并保证仅将患者个人数据用于本协议约定的医疗服务和/或咨询服务，除此之外，不得将患者用户个人数据用于其他任何用途。</p>
    <p>2.【肖像权授权】为便于患者进行检索、对症咨询以及您获取精准患者，您同意将您的真实姓名、省市地区、主要执业点医院名称及科室、职称，以及您补充的个人主页上的信息（如您的真实头像、性别、擅长疾病及个人简介）在诊疗或咨询服务过程中展现和/或在您发布信息的内容分发页面展示，包括康圣友医页面、康圣友医平台页面。</p>
    <p>上述约定的“您的真实头像”涉及您的肖像权，您在此授予康圣友医一项免费且不可撤销地在康圣友医平台和/或其他合作平台使用您肖像的权利。本授权持续有效，直至本协议终止之日止。</p>
    <p class="font-500 font-18">九、知识产权及保密</p>
    <p>（一）知识产权</p>
    <p>1.您知晓并同意，除您依法发布的用户信息及康圣友医另有特别声明外，康圣友医所有系统及平台上所有内容，包括但不限于著作、商标、LOGO、域名、图片、档案、资讯、资料、网站架构、网站画面的安排、网页设计以及任何有关的衍生作品等，均由康圣友医所有</p>
    <p>未经康圣友医书面同意，您和/或任何单位、个人不得复制、修改、出租、出售、散布或以其他商业方式使用平台内的任何内容，不得进行反向工程、反编译、反汇编，或以其他方式发现、提取、或转换源代码，否则康圣友医有权立即单方面终止本协议，并有权要求您赔偿全部损失（包括但不限于调查取证费用、公证费用、律师费等）。</p>
    <p>2.您在平台发布的原创内容的知识产权归您所有。您保证您在平台发布的任何信息不得损害任何第三方合法权益，也不得违反任何政策法规及平台规则。如违反上述约定或因发布的信息内容产生的纠纷/监管等,均由您负责解决并承担法律责任。</p>
    <p>3.您承诺不将已发表于平台的信息，以任何形式发布或授权其它主体以任何方式使用（包括但限于在各类网站、媒体上使用）。</p>
    <p>4.您同意将您在平台发表的任何形式的信息内容（包括但不限于评价、咨询答复、各类话题文章等）的财产性权利等任何可转让的权利，如著作权财产权（复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权等）以及其他可转让的权利，全部独家且不可撤销地转让给康圣友医所有，并同意康圣友医有权就任何主体侵权而单独提起诉讼。</p>
    <p>（二）保密</p>
    <p>您应对您在使用本平台服务过程中所知悉的属于康圣友医和/或患者的保密或专有信息（含个人信息、健康医疗数据等）予以保密，保密期限自您接收或知悉前述约定的保密信息之日起至该等保密信息公开之日止。如您违反保密义务造成康圣友医、患者损失的，您应自行承担全部赔偿责任；若由此导致患者向康圣友医主张权益、诉讼或索赔的，您应配合康圣友医解决争议，并赔偿康圣友医因此产生的全部损失（包括但不限于调查取证费用、公证费用、诉讼费、律师费等）。</p>
    <p class="font-500 font-18">十、关于单项服务与第三方服务的特殊约定</p>
    <p>1.康圣友医平台相关服务中可能包含康圣友医以各种合法方式获取的信息或信息内容链接，同时也包括康圣友医及其关联方合法运营的其他单项服务。这些服务在康圣友医平台可能以单独板块形式存在。康圣友医有权不时地增加、减少或改动这些特别板块的设置及服务。</p>
    <p>2.您可以在康圣友医平台中开启和使用上述单项服务功能。某些单项服务可能需要您同时接受就该服务特别制订的协议或者其他约束您与该项服务提供者之间的规则。必要时康圣友医将以醒目的方式提供这些协议、规则供您查阅。一旦您开始使用上述服务，则视为您理解并接受有关单项服务的相关协议、规则的约束。如未标明使用期限、或未标明使用期限为“永久”、“无限期”或“无限制”的，则这些服务的使用期限为自您开始使用该服务至该服务在康圣友医平台停止提供之日为止。</p>
    <p>3.【第三方服务】您在康圣友医平台中使用第三方提供的软件及相关服务时，除遵守本协议及康圣友医平台中的其他相关规则外，还可能需要同意并遵守第三方的协议、相关规则。如因第三方软件及相关服务产生的争议、损失或损害，由您自行与第三方解决，康圣友医并不就此而对您或任何第三方承担任何责任。</p>
    <p class="font-500 font-18">十一、服务的变更、中断和终止</p>
    <p>1.您理解并同意，康圣友医提供的康圣友医平台相关服务是按照现有技术和条件所能达到的现状提供的。康圣友医会尽最大努力向您提供服务，确保服务的连贯性和安全性。您理解，康圣友医不能随时或始终预见和防范法律、技术以及其他风险，包括但不限于不可抗力、网络原因、第三方服务瑕疵、第三方网站等原因可能导致的服务中断、不能正常使用康圣友医平台相关服务以及其他的损失和风险。</p>
    <p>2.您理解并同意，康圣友医为了整体服务运营、平台运营安全的需要，有权视具体情况决定服务/功能的设置及其范围修改、中断、中止或终止康圣友医平台相关服务。若由此给您造成损失的，康圣友医对该等损失不承担任何责任。</p>
    <p class="fons-500 font-18">十二、法律适用与争议解决</p>
    <p>1.本协议的成立、生效、履行、解释及争议的解决均应适用中华人民共和国法律。倘若本协议之任何规定因与中华人民共和国法律抵触而无效，则这些条款应在不违反法律的前提下按照尽可能接近本协议原条文目的之原则进行重新解释和适用，且本协议其它规定仍应具有完整的效力及效果。</p>
    <p>2.若您与康圣友医发生任何争议，双方应尽量友好协商解决，协商不成，您同意应将争议提交至本协议签订地（杭州市萧山区）有管辖权的法院提起诉讼来寻求解决方案。</p>
    <p class="font-500 font-18">十三、其他</p>
    <p>1.【协议更新】为给您提供更好的服务或因国家法律法规、政策调整、技术条件、产品功能等变化需要，康圣友医会适时对本协议进行修订，修订内容构成本协议的组成部分。本协议更新后，康圣友医会在康圣友医平台发出更新版本，并在更新后的条款生效前通过客户端公告或其他适当的方式提醒您更新的内容，以便您及时了解本协议的最新版本，您也可以在平台设置页面查阅最新版本的协议条款。如您继续使用康圣友医平台相关服务，即表示您已同意接受修订后的本协议内容。</p>
    <p>如您对修订后的协议内容存有异议的，请立即停止登录或使用康圣友医平台相关服务。若您继续登录或使用康圣友医平台及相关服务，即视为您认可并接受修订后的协议内容。</p>
    <p>2.【通知】您同意康圣友医通过下列任一种方式向您发送通知：（1）公告；（2）站内信、客户端推送的消息；（3）根据您预留的联系方式发送的电子邮件、短信、函件等。康圣友医以前述约定的电子方式发出的书面通知，在发送成功后即视为送达；以纸质载体发出的函件等书面通知，在康圣友医交邮后的第五个自然日即视为送达。如多种通知方式并存的，则送达时间以上述方式中最早送达之时为准。</p>
    <p>康圣友医不保证您能够收到或者及时收到该等通知/信息，且不对此承担任何后果。</p>
    <p>3.本协议中的标题仅为方便阅读而设，并不影响本协议中任何规定的含义或解释。</p>
    <p>4.【如何联系我们】如您对本协议内容有任何疑问、意见或建议，您可以通过客服中心（请补充联系方式）与我们联系。</p>
   </div>
  </div>
</template>
<script>
import { ChangePageTitle } from "@/util/title";
export default {
  name: "service-policy",
  data() {
    return {
    };
  },
  methods: {
  },
  created() {
    ChangePageTitle("服务协议");
  },
};
</script>

<style lang="scss" scoped>
.content{
  padding: 16px;
  font-size: 14px;
  color: #666666;
  line-height: 20px;
  text-align: justify;
  .title{
    font-size: 18px;
    color: #333333;
    text-align: center;
    font-weight: 500;
    margin-bottom: 16px;
  }
  .time{
    text-align: right;
    font-size: 12px;
    color: #666666;
  }
  .container{
    margin-top: 16px;
    p{
      text-indent: 2em;
    }
  }
  .font-500{
    font-weight: 500;
    color: #333333;
   
  }
  .font-18{
    font-size: 18px;
    text-indent: 0em !important;
    margin: 16px 0 8px;
  }
  .table{
    width: 100%;
  }
}
</style>
