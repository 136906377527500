// 将request.js 整体导入
import request from './request'

// 按需导出每个请求，也就是按需导出每个api
const apiService = {
    
    // 获取微信config参数
    getWxConfig(url){
        return request.get('/samplenurse/auth/v1/jsConfig?url='+ url)
    },

    //手机号密码登录
    login(data) {
        return request.post('/samplenurse/auth/v1/mobileCode', data)
    },

    //退出登录
    logout(data) {
        return request.get('/samplenurse/auth/v1/loginout', data)
    },

    // 下发验证码
    getCode(data) {
        return request.post('/samplenurse/auth/v1/code', data)
    },

    // 获取护士信息
    getUserConfig() {
        return request.get('/samplenurse/app/query/v1/info')
    },

    // 获取待办列表
    getToDoList(data){
        return request.get('/nurseOrder/query/v1/list',{params:{...data}})
    },

    // 根据核销码查 护士订单详情
    getNurseDetail(code){
        return request.get('/nurseOrder/query/v1/nurseDetail?c=' + code)
    },

    // 去核销
    gotoWriteOff(data){
        return request.post('/orders/v2/verifyNurseCode', data)
    },

    // 获取采集员统计信息
    getStatistics(code){
        return request.get('/samplenurse/app/query/v1/statistics?c=' + code)
    },

    // 获取已核销列表
    getVerifiedOffList(data){
        return request.post('/nurseOrder/query/v1/getMyVerifiedOffOrder', data)
    },

    // 获取采样机构下的所以护士
    getSelect(code){
        return request.get('/samplenurse/app/query/v1/getAdminNurseList?c='+code)
    },

    // 导出
    exprotData(data){
        return request.post('/nurseOrder/query/v1/excel',data)
    }
}
export default apiService;
