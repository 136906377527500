<template>
  <div class="content">
    <div class="top-search">
      <van-search v-model.trim="keyword" placeholder="搜索" @search="search" @clear="clear" />
      <div v-if="!hideHistory && history.length > 0" class="history">
        <div class="top">
          <div>历史搜索</div>
          <span @click='delHistroy' class="iconfont icon-icon_delete"></span>
        </div>
        <div class="record">
          <span v-for="item in history" :key="item" @click="chooseHis(item)" class="item">{{ item }}</span>
        </div>
      </div>
    </div>
    <van-list v-model="loading" :finished="finished" :immediate-check="false" :finished-text="finisheedText" @load="onLoad" offset="20">
      <div class="list">
        <div @click="gotoDetail" v-for="item in list" :key="item.vcc" class="item">
          <div class="top">
            <div>
              <span class="name">{{ item.n }}</span>
              <span v-if="item.s === 2" class="iconfont icon-a-icon_nv3x" style="color: #FF1B61;"></span>
              <span v-if="item.s === 1" class="iconfont icon-a-icon_nan3x" style="color: #1B88FF;"></span>
            </div>
            <div class="quantity">检测项目数量：{{ item.ps.length }}</div>
          </div>
          <div class="time">{{ item.vt }}</div>
          <div class="code">核销码：{{ item.vcc }}</div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { ChangePageTitle } from "@/util/title";
import apiService from "../request/api";

export default {
  name: "search",
  data() {
    return {
      keyword: '',
      loading: false,
      hideHistory: false,
      list: [],
      finisheedText: '暂无数据',
      finished: false,
    };
  },
  computed: {
    code() {
      return JSON.parse(localStorage.getItem('config')).sc
    },
    history() {
      return JSON.parse(localStorage.getItem('history_write_off')) || [] // 获取搜索记录 没有的话直接返回空数组
    }
  },
  methods: {
    search() {
      // 查询是否输入之前有的数据 有就不予以追加
      if(this.keyword){
        this.cacheHistory(this.keyword)
        this.list = []
        this.getList()
      }
    },

    chooseHis(e){
      this.keyword = e
      this.getList()
    },

    delHistroy() { 
      this.history = []
      localStorage.removeItem('history_write_off')
    },

    clear() {
      this.hideHistory = false
      this.
      this.getList()
    },
    
    // 缓存搜索记录
    cacheHistory(val) {
      let isExist = this.history.some(item=> item === val) 
      if(!isExist){
        if(this.history.length === 10){
          this.history.pop()
          this.history.unshift(val)
          localStorage.setItem('history_write_off',JSON.stringify(this.history))
        }else{
          this.history.unshift(val)
          localStorage.setItem('history_write_off',JSON.stringify(this.history))
        }
      }
    },
    gotoDetail() {
      this.$router.push('/order')
    },
    // 获取列表数据
    async getList() {
      if(this.keyword==='') return
      const pop = this.list[this.list.length - 1];
      let params = {
        n: this.keyword,
        sc: this.code,
        ct: pop ? pop.vt : ''
      }
      try {
        let res = await apiService.getVerifiedOffList(params)
        this.list = this.list.concat(res.data.ls)
        this.hideHistory = true
        if (res.data.ls.length == 0) {
          this.finished = true
          this.finisheedText = '没有更多了'
        }
        if (this.list.length == 0) {
          this.finisheedText = '暂无数据'
        }
      } catch (error) {
        this.finished = true
        console.log(error);
      }
    },

    // 上拉加载
    async onLoad() {
      try {
        await this.getList()
        this.loading = false;
        this.refreshing = false;
      } catch {
        this.loading = false;
        this.finished = true;
        this.refreshing = false;
      }
    },
  },
  created() {
    ChangePageTitle("搜索");
  },
};
</script>

<style lang="scss" scoped>
.content {
  height: 100%;
  background-color: #f7f7f7;
}

.display-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-search {
  width: 375px;
  background: #FFFFFF;
  position: sticky;
  top: 0;

  .van-search__content {
    width: 262px;
    height: 34px;
    background: #FFFFFF;
    border-radius: 22px;
    border: 1px solid #CCCCCC;
  }

  .history {
    margin-top: 14px;
    font-size: 16px;
    color: #000000;
    font-weight: 500;
    padding: 0 16px;

    .top {
      display: flex;
      justify-content: space-between;

      .iconfont {
        font-size: 24px;
      }
    }

    .record {
      display: flex;
      flex-wrap: wrap;
      margin-top: 13px;
      padding-bottom: 6px;

      .item {
        padding: 5px 11px;
        background-color: #F2F2F2;
        font-size: 12px;
        color: #333333;
        border-radius: 14px;
        max-width: 60px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: 12px;
        margin-bottom: 12px;
      }
    }
  }
}

.list {
  width: 343px;
  margin: auto;
  margin-top: 12px;

  .item {
    width: 100%;
    background: #FFFFFF;
    border-radius: 6px;
    padding: 16px;
    box-sizing: border-box;
    font-size: 14px;
    color: #999999;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .name {
        color: #333333;
        font-weight: 500;
        width: 112px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 13px;
      }

      .quantity {
        font-size: 14px;
        color: #333333;
      }
    }

    .time {
      margin-top: 12px;
    }

    .code {
      margin-top: 4px;
    }
  }
}
</style>
