import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store  from './store'
import './assets/iconfont/iconfont.css';
import '../src/assets/iconfont/iconfont.js'
import apiService from './request/api';
import osspath from './util/config';

import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);
// 路由守卫

// to：即将要进入的目标 路由对象
// from：当前导航正要离开的路由

//next：放行
router.beforeEach((to, from, next) => {
    if (to.matched.length != 0) {
        if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
            if (Boolean(localStorage.getItem("token"))) { // 通过浏览器本地缓存判断当前的token是否存在
                next();
            } else {
                next({
                    path: '/login',
                    query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
                })
            }
        } else {
            if (Boolean(localStorage.getItem("token"))) { // 判断是否登录
                if (to.path != "/home" && to.path != "/login") { //判断是否要跳到登录界面
                    next();
                } else {
                    /**
                     * 防刷新，如果登录，修改路由跳转到登录页面，修改路由为登录后的首页
                     */
                    next({
                        path: '/home'
                    })
                }
            } else {
                next();
            }
        }
    } else {
        next({
            path: '/login',
            query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
        })
    }
})
Vue.config.productionTip = false;
Vue.prototype.$apiService = apiService;
Vue.prototype.$osspath = osspath;

new Vue({
	router,		
    store,
	render: (h) => h(App),
}).$mount('#app');
