<template>
  <div style="height: 100%;background-color: #f7f7f7;padding-top: 12px;">
    <div class="user">
      <div class="image">
        <img src="../assets/image/user.png" alt="">
      </div>
      <div class="name">{{ USER_CONFIG.n }}</div>
      <div class="display-flex"><span class="hosp">{{ USER_CONFIG.sn }}</span><span class="role">{{ USER_CONFIG.ia == 1 ? '管理员' : '护士' }}</span></div>
      <div class="statistics">
        <div class="item">
          <div class="title">今日核销</div>
          <div>{{totay}}单</div>
        </div>
        <span></span>
        <div class="item">
          <div class="title">当月核销</div>
          <div>{{month}}单</div>
        </div>
      </div>
    </div>
    <div class="nav">
      <div class="item" @click="gotoCancelled">
        <div class="display-flex"><span class="iconfont icon-a-icon_hexiaodingdan"></span>已核销订单</div>
        <div class="iconfont arrows icon-a-icon_right3x"></div>
      </div>
      <div class="item" @click="gotoSite">
        <div class="display-flex"><span class="iconfont icon-a-icon_shezhi"></span>设置</div>
        <div class="iconfont arrows icon-a-icon_right3x"></div>
      </div>
    </div>
    <!-- tabbar -->
    <van-tabbar route active-color="#F86D0C" inactive-color="#999999">
      <van-tabbar-item replace to="/home">
        <template #icon="props">
          <svg class="icon" aria-hidden="true">
            <use :xlink:href="props.active ? '#icon-a-icon_wode13x' : '#icon-a-icon_wode23x'"></use>
          </svg>
        </template>
        待办
      </van-tabbar-item>
      <van-tabbar-item @click="scanning">
        <template #icon="props">
          <svg class="icon" aria-hidden="true">
            <use :xlink:href="props.active ? '#icon-a-icon_saoma13x' : '#icon-a-icon_saoma23x'"></use>
          </svg>
        </template>
        扫码核销
      </van-tabbar-item>
      <van-tabbar-item replace to="/mine">
        <template #icon="props">
          <svg class="icon" aria-hidden="true">
            <use :xlink:href="props.active ? '#icon-a-icon_daiban13x' : '#icon-a-icon_daiban23x'"></use>
          </svg>
        </template>
        我的
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { ChangePageTitle } from "@/util/title";
import apiService from "../request/api";
import { Toast } from 'vant';
import wx from 'weixin-js-sdk'
import { isWeixinEnv, wxInitConfig } from "../util/wx";
export default {
  name: "mine",
  data() {
    return {
      loading: false,
      totay: 0,
      month: 0
    };
  },

  mounted() {
    this.getData()
  },

  computed: {
    USER_CONFIG() {
      return JSON.parse(localStorage.getItem('config'))
    }
  },
  methods: {
    gotoCancelled() {
      this.$router.push('/cancelled')
    },
    gotoSite() {
      this.$router.push('/site')
    },
    
    async scanning() {
      let _this = this
      if (isWeixinEnv()) {
        try {
          wx.scanQRCode({
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
            success: async function (res) {
              var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
              _this.writeOffHandle(result)
            },
            fail: function (error) {
              console.log(error);
            }
          })
        } catch (err) {
          console.log(err);
        }
      } else {
        Toast('当前浏览器不支持此操作！请在微信浏览器下处理')
      }
    },

    async writeOffHandle(code) {
      let response = await apiService.getNurseDetail(code)
      if (response.code === 0) {
        localStorage.setItem('order_detail',JSON.stringify(response.data))
        this.$router.push('/order')
      }
    },

    async getData() {
      try {
        let config = JSON.parse(localStorage.getItem('config'))
        let res = await apiService.getStatistics(config.sc)
        if(res.code === 0){
          this.totay = res.data.js,
          this.month = res.data.ds
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 上拉加载
    async onLoad() {
      const pop = this.list[this.list.length - 1];
      try {
        await this.getMySevenList(pop ? pop.ct : '')
        this.loading = false;
        this.refreshing = false;
      } catch {
        this.loading = false;
        this.finished = true;
        this.refreshing = false;
      }
    },
  },
  async created() {
    ChangePageTitle("我的");
    let url = window.location.href.split('#')[0]
    let res = await apiService.getWxConfig(url)
    wxInitConfig(res.data)
  },
};
</script>

<style lang="scss" scoped>
.display-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.user {
  width: 343px;
  padding-bottom: 20px;
  margin: auto;
  background: #FFFFFF;
  border-radius: 8px;
  text-align: center;

  .image {
    margin-top: 16px;
    display: inline-block;
    width: 70px;
    height: 70px;

    >img {
      width: 100%;
      height: 100%;
    }
  }

  .name {
    margin-top: 16px;
    font-size: 20px;
    color: #333333;
    margin-bottom: 8px;
  }

  .hosp {
    max-width: 252px;
    color: #999999;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 10px;
  }

  .role {
    color: #999999;
    font-size: 14px;
  }

  .statistics {
    margin-top: 29px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .item {
      width: 171px;
      height: 44px;
      color: #666666;
      font-weight: 500;

      .title {
        font-size: 14px;
        color: #999999;
        margin-bottom: 4px;
        font-weight: 400;
      }
    }

    span {
      width: 1px;
      height: 16px;
      background: #E6E6E6;
    }
  }
}

.nav {
  margin: auto;
  margin-top: 12px;
  width: 343px;
  background-color: #FFFFFF;
  border-radius: 8px;

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 16px;
    border-bottom: 1px solid #F7F7F7;
    font-size: 14px;
    color: #333333;

    span {
      margin-right: 12px;
    }

    .iconfont {
      font-size: 22px;
    }

    .arrows {
      color: #cccccc;
    }
  }
}
</style>
