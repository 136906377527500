export const formatTime = (date, string='yyyy-MM-dd HH-mm-ss') => {

	const year = date.getFullYear()
	const month = date.getMonth() + 1
	const day = date.getDate()
	const hour = date.getHours()
	const minute = date.getMinutes()
	const second = date.getSeconds()

  if(string === 'yyyy-MM-dd'){
    return(
      [year, month, day].map(formatNumber).join('-')
    )
  }else if(string === 'yyyy-MM-dd HH-mm-ss'){
    return (
      [year, month, day].map(formatNumber).join('-') +
      ' ' +
      [hour, minute, second].map(formatNumber).join(':')
    )
  }else{
    throw new Error("时间格式错误！");
  }
}

const formatNumber = (val) => {
	const s = val.toString()
	return s[1] ? s : '0' + s
}