<template>
  <div class="content">
    <div class="top">
      <div class="code">核销码：{{ ORDER_DETAIL.vc }}</div>
      <div class="display-flex">
        <div class="card">
          <div class="title">患者姓名</div>
          <div class="value">{{ORDER_DETAIL.pn}}</div>
        </div>
        <div class="card">
          <div class="title">预约时间</div>
          <div class="value">{{ ORDER_DETAIL.mt }}</div>
        </div>
      </div>
    </div>
    <div v-if="ORDER_DETAIL.is === 0" class="yellow tip">该样本采样完成后，请做好标识提示患者自行带走！</div>
    <div v-else class="blue tip">该样本采样完成后，请做好标识等待物流取样！</div>
    <div class="order-project">
      <div class="title">检测项目及标本要求</div>
      <div class="project-list">
        <div v-for="(item,index) in ORDER_DETAIL.ps" :key="index" class="item">
          <div class="name">{{ index + 1 }}.{{ item.n }}</div>
          <div class="samp-require">{{ item.r }}</div>
        </div>
      </div>
    </div>
    <div class="order-info">
      <div class="title">订单信息</div>
      <div class="item">
        <div class="label">订单编号</div>
        <div class="value">{{ ORDER_DETAIL.n }}</div>
      </div>
      <div class="item">
        <div class="label">下单时间</div>
        <div class="value">{{ ORDER_DETAIL.ct }}</div>
      </div>
      <div class="item">
        <div class="label">付款时间</div>
        <div class="value">{{ ORDER_DETAIL.pt }}</div>
      </div>
      <div class="item" v-if="ORDER_DETAIL.vt">
        <div class="label">核销时间</div>
        <div class="value">{{ ORDER_DETAIL.vt }}</div>
      </div>
    </div>
    <div style="height: 80px;"></div>
    <div class="fixed-btn" v-if="ORDER_DETAIL.os === 2">
      <van-button v-if="ORDER_DETAIL.vt" class="gray" disabled round block>已核销</van-button>
      <van-button v-else class="btn" round block :loading="loading" @click="confirmWriteOff">确认核销</van-button>
    </div>
    <van-popup v-model="showConfirm" class="pop">
      <div class="pop-content" v-if="ORDER_DETAIL.is === 0">
        <img class="img" src="../assets/image/pop_yellow.png" alt="">
        <div class="box">
          <div class="title">核销完成</div>
          <div>该样本采样完成后，请做好标识</div>
          <div class="tip">提示患者自行带走 !</div>
          <van-button class="btn yellow" round block @click="confirm">确认</van-button>
        </div>
      </div>
      <div v-else class="pop-content">
        <img class="img" src="../assets/image/pop_blue.png" alt="">
        <div class="box">
          <div class="title">核销完成</div>
          <div>该样本采样完成后，请做好标识</div>
          <div class="tip">等待物流取样 !</div>
          <van-button class="btn blue" round block @click="confirm">确认</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>


<script>
import { ChangePageTitle } from '@/util/title'
import apiService from '../request/api'
export default {
  name: "order",
  data() {
    return {
      showConfirm: false,
      loading: false,
      code: '',
    };
  },
  computed:{
    ORDER_DETAIL() {
      return JSON.parse(localStorage.getItem('order_detail'))
    }
  },
  methods:{
    // 确认核销
    async confirmWriteOff() {
      this.loading = true
      try {
        let params = {
          vc: this.ORDER_DETAIL.vc,
          tp: new Date().getTime()
        }
        let res = await apiService.gotoWriteOff(params)
        if(res.code === 0){
          this.loading = false;
          this.showConfirm = true
        }
      } catch (error) {
        this.loading = false;
      }
    },

    async getDetail(code) {
      let res = await apiService.getNurseDetail(code)
      if (res.code === 0) {
        localStorage.setItem('order_detail',JSON.stringify(res.data))
      }
    },

    // 核销接口
    async confirm(){
      this.$router.replace('/home')
    }
  },

  mounted() {
    let code = this.$route.query.c
    if(code){ this.getDetail(code) }
  },

  // 设置浏览器标题
  created() {
     ChangePageTitle('订单详情')
  },
};
</script>

<style lang="scss" scoped>
.content{
  background-color: #f7f7f7;
  padding-top: 12px;
  min-height: calc(100vh - 12px);
}
.top {
  width: 343px;
  height: 126px;
  background: #FFFFFF;
  border-radius: 8px 8px 0px 0px;
  margin: auto;
  padding: 16px;
  box-sizing: border-box;
}
.display-flex{
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.card{
  margin-top: 16px;
  padding: 6px 0;
  box-sizing: border-box;
  width: 147px;
  height: 56px;
  background: #FAFAFA;
  border-radius: 8px;
  font-size: 14px;
  text-align: center;
  .title{
    color: #999999;
  }
  .value{
    margin-top: 4px;
    color: #333333;
  }
}
.tip{
  width: 343px;
  height: 28px;
  background: rgba(248,109,12,0.1);
  border-radius: 0px 0px 8px 8px;
  font-size: 14px;
  margin: auto;
  line-height: 28px;
  text-align: center;
}
.blue{
  background: rgba(27,136,255,0.1);
  color: #1B88FF;
}
.yellow{
  background: rgba(248,109,12,0.1);
  color: #924D1D;
}
.order-project{
  width: 343px;
  margin: auto;
  padding: 16px;
  padding-bottom: 20px;
  box-sizing: border-box;
  background-color: #FFFFFF;
  margin-top: 12px;
  border-radius: 8px;
  .title{
    font-size: 16px;
    color: #999999;
    font-weight: 500;
    margin-bottom: 16px;
  }
  .project-list{
    background-color: #FFFFFF;
    .item{
      margin-bottom: 16px;
      border-bottom: 1px solid #F7F7F7;
      padding-bottom: 16px;
      &:last-child{
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
    .name{
      font-size: 14px;
      color: #333333;
      font-weight: 500;
    }
    .samp-require{
      margin-top: 6px;
      background-color: #FFF6F2;
      color: #F86D0C;
      padding: 2px 4px;
      line-height: 16px;
      font-size: 12px;
    }
  }
}
.order-info{
  width: 343px;
  margin: auto;
  padding: 16px;
  box-sizing: border-box;
  background-color: #FFFFFF;
  margin-top: 12px;
  border-radius: 8px;
  .title{
    font-size: 16px;
    color: #999999;
    font-weight: 500;
    margin-bottom: 16px;
  }
  .item{
    display: flex;
    align-items: center;
    margin-top: 10px;
    .label{
      // width: 56px;
      font-size: 14px;
      color: #666666;
      margin-right: 22px;
    }
    .value{
      font-size: 14px;
      color: #333333;
    }
  }
}
.fixed-btn{
  position: fixed;
  bottom: 0;
  background-color: #FFFFFF;
  width: 100%;
  padding: 8px 16px;
  border-top: 1px solid #F7F7F7;
  .btn{
    width: 343px;
    height: 42px;
    background-color: #F86D0C;
    color: #FFFFFF;
  }
  .gray{
    width: 343px;
    height: 42px;
    background-color: #D9D9D9;
    color: #FFFFFF;
    opacity: 1;
  }
}
.pop{
  background-color: transparent;
}
.pop-content{
  width: 290px;
  height: 266px;
  background-color: transparent;
  text-align: center;
  .img{
    width: 82px;
    height: 82px;
    margin-bottom: -64px;
  }
  .box{
    width: 290px;
    border-radius: 8px;
    background-color: #FFFFFF;
    text-align: center;
    padding-top: 60px;
    color: #666666;
    font-size: 14px;
    padding-bottom: 20px;
    box-sizing: border-box;
    .title{
      font-size: 17px;
      color: #353535;
      font-weight: 500;
      margin-bottom: 8px;
    }
    .tip{
      width: 146px;
      height: 20px;
      font-size: 14px;
      background: #EDEDED;
      border-radius: 4px;
      margin-top: 4px;
      margin-bottom: 28px;
      line-height: 20px;
    }
    .btn{
      width: 238px;
      height: 42px;
      margin: auto;
      background-color: #F86D0C;
      color: #FFFFFF;
    }
    .yellow{
      background-color: #F86D0C;
      font-weight: 500;
      font-size: 17px;
      color: #FFFFFF;
      letter-spacing: 2px;
    }
    .blue{
      background-color: #1B88FF;
      font-weight: 500;
      font-size: 17px;
      color: #FFFFFF;
      letter-spacing: 2px;
    }
  }
}
</style>