import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Mine from '../views/Mine.vue'
import Order from '../views/Order.vue'
import Cancelled from '../views/Cancelled.vue';
import Search from '../views/Search.vue';
import Site from '../views/Site.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue';
import ServicePolicy from '../views/ServicePolicy.vue'
import About from '../views/About.vue'

Vue.use(VueRouter);

const routes = [

	{ path: '/', redirect: '/home' },
	{
		path: '/login',
		name: 'login',
		component: Login,
	},
	{
		path: '/order',
		name: 'order',
		component: Order,
		meta: {
			requireAuth: true // 判断该路由是否需要登录权限
		}
	},
	{
		path: '/cancelled',
		name: 'cancelled',
		component: Cancelled,
		meta: {
			requireAuth: true // 判断该路由是否需要登录权限
		}
	},
	{
		path: '/search',
		name: 'search',
		component: Search,
		meta: {
			requireAuth: true // 判断该路由是否需要登录权限
		}
	},
	{
		path: '/site',
		name: 'site',
		component: Site,
	},
	{
		path: '/privacy-policy',
		name: 'privacy-policy',
		component: PrivacyPolicy,
	},
	{
		path: '/service-policy',
		name: 'service-policy',
		component: ServicePolicy,
	},
	{
		path: '/about',
		name: 'about',
		component: About,
	},
	{
		path: '/home',
		name: 'Home',
		component: Home,
		meta: {
			requireAuth: true // 判断该路由是否需要登录权限
		}
	},
	{
		path: '/mine',
		name: 'mine',
		component: Mine,
		meta: {
			requireAuth: true // 判断该路由是否需要登录权限
		}
	},

	{
		name: '404',
		path: '/404',
		component: () => import('@/views/404.vue'),
	},
];

const router = new VueRouter({
	routes,
	// history模式
	// mode: 'hash',
	// base: '/home/',
});

export default router;
