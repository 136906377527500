// store/index.js
import Vue from 'vue';
import Vuex from 'vuex';
 
Vue.use(Vuex);
 
export default new Vuex.Store({
  state: {
    ORDER_DETAIL: {} //  核销码的时候 查询订单详情数据
  },

  mutations: {
    SET_ORDER_DETAIL(state,payload) {
      state.ORDER_DETAIL = payload
    },
  },

  getters: {
    getOrderDetail(state){
      return state.ORDER_DETAIL
    }
  }
});