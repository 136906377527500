import wx from 'weixin-js-sdk'

export const wxInitConfig = (confog) => {
    const appId = confog.appId;
    const timestamp = confog.timestamp;
    const nonceStr = confog.nonceStr;
    const signature = confog.signature;
    wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。（测试记得关掉）
        appId: appId, // 必填，公众号的唯一标识
        timestamp: timestamp, // 必填，生成签名的时间戳
        nonceStr: nonceStr, // 必填，生成签名的随机串
        signature: signature, // 必填，签名，见附录1
        jsApiList: ['checkJsApi', 'scanQRCode'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
    });

    // wx.ready(function () {
    //     wx.checkJsApi({
    //         jsApiList: ['checkJsApi','scanQRCode'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
    //         success: function (res) {
    //             // 以键值对的形式返回，可用的api值true，不可用为false
    //             // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
    //             console.log(res, 'checkJsApi')
    //         },
    //         fail: function () {
    //             console.log('当前登录环境不支持扫码,请切换微信环境');
    //         }
    //     });

    //     wx.scanQRCode({
    //         needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
    //         scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
    //         success: function (res) {
    //             var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
    //             return result
    //         },
    //         fail: function (error) {
    //             console.log(error);
    //         }
    //     })
    // });
    // wx.error(function (res) {
    //     // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
    //     console.log('验证失败返回的信息:', res);
    // });

}

export const wxScanQRCode = () => {
    wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: function (res) {
            var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
            return result
        },
        fail: function (error) {
            console.log(error);
        }
    })
}

// 判断是否在小程序内 
export const isWeixinEnv = () => {
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true; // 在微信浏览器中打开
    } else {
        return false; // 在普通浏览器中打开
    }
}