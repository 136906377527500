<template>
  <div style="overflow: hidden;">
    <div class="logo">
      <img src="../assets/image/logo.png" alt="">
    </div>
    <p class="title">康圣友医采样核销平台</p>
    <van-cell-group :border="false">
     <div class="phone-input">
      <span class="text">+86</span>
      <van-field
        class="input"
        clearable
        v-model.trim="phone"
        placeholder="输入手机号"
        @input="input"
        maxlength="11"
      />
     </div>
     <div class="code-input">
      <van-field
        class="input"
        clearable
        v-model.trim="code"
        placeholder="输入验证码"
        @input="input"
        maxlength="6"
      >
        <template #button>
          <div>
            <span v-if="countDownNum <= 0" @click="sendCode">获取验证码</span>
            <span v-else>{{countDownNum}}s</span>
          </div>
        </template>
      </van-field>
     </div>
    </van-cell-group>
    <div class="loginBtn">
      <van-button :class="adopt ? 'btn-confirm' : 'btn-none'" round block @click="login" :loading="loading">登录</van-button>
    </div>
    <div class="tip">
      <van-checkbox class="check" v-model="checked" icon-size="18" checked-color="#F86D0C">
      </van-checkbox>
     <div class="text" style="margin-left: 8px;">
      登录需您阅读并同意<span @click="gotoServicePolicy">《康圣友医采样核销平台服务协议》</span><span @click="gotoPrivacyPolicy">《隐私协议》</span>
     </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import { ChangePageTitle } from '@/util/title'
import apiService from '../request/api'
export default {
  name: "login",
  data() {
    return {
      phone: "",
      code: "",
      checked: false,
      adopt: false,
      countDownNum: 0,
      timeId: null,
      loading: false,
    };
  },
  methods:{
    // 监听输入事件
    input() {
      if(this.phone && this.code) {
        this.adopt = true
      }else{
        this.adopt = false
      }
    },

    // 去登录
    async login() {
      if(!this.phone) {
        return Toast('请输入手机号')
      }else if(!/^1[3456789]\d{9}$/.test(this.phone)){
        return Toast("请输入正确的手机号");
      }else if(!this.code) {
        return Toast('请输入验证码')
      }else if(!this.checked){
        return Toast('请阅读并同意协议后登录')
      }
      const params = {
        p: this.phone,
        c: this.code
      }
      this.loading = true
      try {
        const res = await apiService.login(params)
        localStorage.setItem('token', res.data.t)
        const config = await apiService.getUserConfig()
        localStorage.setItem('config', JSON.stringify(config.data))

        setTimeout(() => {
            this.loading = false
            this.$router.push('/home')
        }, 100);
      } catch {
        this.loading = false;
      }
     
    },
    async sendCode(){
      if(!this.phone) {
        return Toast('请输入手机号')
      }else if(!/^1[3456789]\d{9}$/.test(this.phone)){
        return Toast("请输入正确的手机号");
      }
      let params = {
        p: this.phone
      }
      const res = await apiService.getCode(params)
      if(res.code === 0){
        this.startCountdown(60)
      }
    },

    // 验证码
    startCountdown(seconds) {
      this.countDownNum = seconds
      this.timeId =  setInterval(()=>{
          this.countDownNum = this.countDownNum - 1
          if(this.countDownNum < 0){
            clearInterval(this.timeId) 
          }
        },1000)
    },

    gotoPrivacyPolicy() {
      this.$router.push('/privacy-policy')
    },
    gotoServicePolicy() {
      this.$router.push('/service-policy')
    },
  },

  // 设置浏览器标题
  created() {
     ChangePageTitle('登录')
  },

  destroyed(){
    clearInterval(this.timeId)
  }
  
};
</script>

<style lang="scss" scoped>
.logo{
  text-align: center;
  margin-top: 126px;
  >img{
    height: 70px;
    height: 70px;
  }
}
.title {
  margin-top: 24px;
  font-size: 21px;
  text-align: center;
  color: #333;
  font-weight: 500;
}
.van-cell-group {
  margin: 110px 16px 0 16px;
}
.input {
  height: 100%;
  width: 300px;
}
.phone-input{
  display: flex;
  align-items: center;
  width: 343px;
  height: 44px;
  padding: 0 20px;
  box-sizing: border-box;
  border-radius: 29px;
  border: 1px solid #E6E6E6;
  margin-bottom: 20px;
  .text{
    font-size: 14px;
    color: #333333;
    display: flex;
    align-items: center;
    &::after{
      content: ' ';
      width: 1px;
      height: 6px;
      background: #D8D8D8;
      border-radius: 1px;
      margin-left: 12px;
    }
  }
}
.code-input{
  display: flex;
  align-items: center;
  width: 343px;
  height: 44px;
  padding: 0 20px;
  box-sizing: border-box;
  border-radius: 29px;
  border: 1px solid #E6E6E6;
  margin-bottom: 20px;
  .input{
    padding-left: 0;
    width: 100%;
  }
}
.van-cell::after {
  border: 0;
}
.loginBtn {
  margin: 60px 16px 0 16px;
  .btn-confirm {
    background: #F86D0C;
    color: #fff;
    height: 44px;
  }
  .btn-none {
    background: #E6E6E6;
    color: #fff;
    height: 44px;
  }
}
.tip {
  margin: 10px 16px 0;
  color: #999;
  font-size: 14px;
  display: flex;
  align-items: center;
  .check {
    width: 20px;
    .van-checkbox__icon--round{
      width: 16px;
      height: 16px;
    }
  }
  .text{
    width: 319px;
  }
  span{
    color: #1B88FF;
  }
}
::v-deep input::placeholder{
    color: #999;
}
</style>