<template>
  <div class="content">
    <div class="top-search">
      <van-search @click="gotoSearch" v-model="keyword" readonly placeholder="搜索患者姓名、核销码" />
    </div>
    <div class="search">
      <div class="box">
        <div @click="chooseNurse" class="name">{{ name || '全部' }}<van-icon style="margin-left: 7px;" size="10"
            name="arrow-down" />
        </div>
        <div @click="chooseTime" class="time">{{ startTime || '开始时间' }}～{{ endTime || '结束时间' }}<van-icon
            style="margin-left: 7px;" size="10" name="arrow-down" /></div>
      </div>
      <div class="sales">
        <div class="value">{{ total }}</div>
        <div>核销单量</div>
      </div>
    </div>

    <div style="padding-bottom: 60px;">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" :finished-text="finisheedText"
          @load="onLoad" offset="20">
          <div class="list">
            <div @click="gotoDetail(item.vcc)" v-for="item in list" :key="item.vcc" class="item">
              <div class="top">
                <div>
                  <span class="name">{{ item.n }}</span>
                  <span v-if="item.s === 2" class="iconfont icon-a-icon_nv3x" style="color: #FF1B61;"></span>
                  <span v-if="item.s === 1" class="iconfont icon-a-icon_nan3x" style="color: #1B88FF;"></span>
                </div>
                <div class="quantity">检测项目数量：{{ item.ps.length }}</div>
              </div>
              <div class="time">{{ item.vt }}</div>
              <div class="code">核销码：{{ item.vcc }}</div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <!-- 底部导出按钮 -->
    <div class="fixed-btn">
      <van-button class="btn" round block @click="exprotData">导出数据</van-button>
    </div>
    <!-- 管理员角色 选择护士 -->
    <van-popup v-model="show" class="popup" round position="bottom">
      <van-picker show-toolbar :columns="columns" value-key="n" confirm-button-text="确定" cancel-button-text="取消"
        @confirm="onConfirm" @cancel="onCancel"/>
    </van-popup>
    <!-- 时间范围选择器 -->
    <van-popup v-model="showTime" class="popup-time" round position="bottom" :style="{ height: '60%' }">
      <div class="top">
        <span @click="onCancelTime" class="cancel">取消</span>
        <span @click="onConfirmTime" class="confirm">确定</span>
      </div>
      <div class="time-range">
        <span @click="chooseStartTime" :class="timeType === 'START' ? 'active time' : 'time'">{{ startTime || '- -'
          }}</span>
        <span>至</span>
        <span @click="chooseEndTime" :class="timeType === 'END' ? 'active time' : 'time'">{{ endTime || '- -' }}</span>
      </div>
      <div class="tip">最长可查找时间跨度6个月的订单</div>
      <van-datetime-picker v-model="chooseDate" @change="onChangeTime" :show-toolbar="false" type="date"
        :min-date="minDate" :max-date="maxDate" :formatter="formatter" />
    </van-popup>
    <!-- 导出数据 -->
    <van-popup v-model="showPhone" class="popup-phone" round closeable>
      <div class="phone-box">
        <img src="../assets/image/pop-phone.png" alt="">
        <div style="margin-top: 13px;">请确认手机号是否正确</div>
        <van-field class="input" :border="false" label="手机号" input-align="right" maxLength="11" v-model.trim="phone" placeholder="请输入手机号" />
        <van-button class="export-btn" round block :loading="exprotLoading" @click="confirmExprot">确认导出</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { ChangePageTitle } from "@/util/title";
import { formatTime } from "@/util/formatTime.js"
import { Toast } from 'vant';
import apiService from "../request/api";

export default {
  name: "cancelled",
  data() {
    return {
      keyword: '',
      show: false,
      columns: [],
      id: '',
      name: '',
      total: 0,
      showTime: false,
      minDate: new Date(2024, 9, 1),
      maxDate: new Date(),
      chooseDate: new Date(),
      timeType: 'START', // 时间选择器的类型
      startTime: '',
      endTime: '',
      showPhone: false,
      phone: '', // 手机号
      list: [],
      loading: false,
      finisheedText: '暂无数据',
      refreshing: false,
      finished: false,
      exprotLoading: false,
    };
  },
  async mounted() {
    await this.getSelect()
  },
  computed: {
    code() {
      return JSON.parse(localStorage.getItem('config')).sc
    },
    // phone() {
    //   return JSON.parse(localStorage.getItem('config')).sc
    // }
  },
  methods: {
    // 格式化时间选择器
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      } else if (type === 'day') {
        return `${val}日`;
      }
      return val;
    },
    gotoSearch() {
      this.$router.push('/search')
    },

    gotoDetail(e) {
      this.$router.push({path: '/order', query: { c: e }} )
    },
    // 导出数据
    exprotData() {
      this.showPhone = true
    },

    // 选择护士
    chooseNurse() {
      this.show = true
    },

    // 确认护士
    onConfirm(e) {
      this.id = e.id
      this.name = e.n
      this.show = false
      this.initList()
    },

    onCancel() {
      this.show = false
    },
    
    // 选择时间
    chooseTime() {
      this.showTime = true
    },
    // 选择开始时间
    chooseStartTime() {
      this.timeType = 'START'
    },
    // 选择结束时间
    chooseEndTime() {
      this.timeType = 'END'
    },
    onCancelTime() {
      this.showTime = false
    },

    onChangeTime() {
      if (this.timeType === 'START') {
        try {
          let result = formatTime(new Date(this.chooseDate), 'yyyy-MM-dd')
          this.startTime = result
        } catch (error) {
          console.log(error);
        }
      } else if (this.timeType === 'END') {
        try {
          let result = formatTime(new Date(this.chooseDate), 'yyyy-MM-dd')
          this.endTime = result
        } catch (error) {
          console.log(error);
        }
      }
    },

    // 确定时间
    onConfirmTime() {
      if (this.startTime == '') {
        return Toast('未选择开始时间');
      }
      if (this.endTime == '') {
        return Toast('未选择结束时间');
      }
      if (new Date(this.startTime).getTime() > new Date(this.endTime).getTime()) {
        let temp = this.startTime
        this.startTime = this.endTime
        this.endTime = temp
      }
      this.showTime = false
      this.initList()
    },

    async getSelect() {
      try {
        let config = JSON.parse(localStorage.getItem('config'))
        let res = await apiService.getSelect(config.sc)
        if (res.code === 0) {
          let obj = {n: '全部', id: ''}
          this.columns = [obj,...res.data]
        }
      } catch (error) {
        console.log(error);
      }
    },

    // 确认导出
    async confirmExprot() {
      if(!this.phone) {
        return Toast('请输入手机号')
      }else if(!/^1[3456789]\d{9}$/.test(this.phone)){
        return Toast("请输入正确的手机号");
      }
      let params = {
        ni: this.id,
        p: this.phone,
        st: this.startTime ? this.startTime + ' 00:00:00' : '',
        et: this.endTime ? this.endTime + ' 23:59:59' : '',
        sc: this.code,
      }
      this.exprotLoading = true
      try {
        let res = await apiService.exprotData(params)
        if (res.code === 0) {
          this.exprotLoading = false
          this.showPhone = false
        }
      } catch (error) {
        this.exprotLoading = false
        console.log(error);
      }
    },

    async onRefresh() {
      // 清空列表数据
      this.list = []
      this.loading = true
      this.initList()
    },

    // 刷新数据
    async initList() {
      let params = {
        ni: this.id,
        st: this.startTime ? this.startTime + ' 00:00:00' : '',
        et: this.endTime ? this.endTime + ' 23:59:59' : '',
        sc: this.code,
        ct: ''
      }
      try {
        let res = await apiService.getVerifiedOffList(params)
        this.list = res.data.ls
        this.total = res.data.s // 总条数
        this.loading = false
        this.refreshing = false
        if (res.data.ls.length == 0) {
          this.finished = true
          this.finisheedText = '没有更多了'
        }
        if (this.list.length == 0) {
          this.finisheedText = '暂无数据'
        }
      } catch (error) {
        this.finished = true
      }
    },

    // 获取列表数据
    async getList() {
      const pop = this.list[this.list.length - 1];
      let params = {
        ni: this.id,
        st: this.startTime ? this.startTime + ' 00:00:00' : '',
        et: this.endTime ? this.endTime + ' 23:59:59' : '',
        sc: this.code,
        ct: pop ? pop.vt : ''
      }
      try {
        let res = await apiService.getVerifiedOffList(params)
        this.list = this.list.concat(res.data.ls)
        this.total = res.data.s // 总条数
        if (res.data.ls.length == 0) {
          this.finished = true
          this.finisheedText = '没有更多了'
        }
        if (this.list.length == 0) {
          this.finisheedText = '暂无数据'
        }
      } catch (error) {
        this.finished = true
      }
    },

    // 上拉加载
    async onLoad() {
      try {
        await this.getList()
        this.loading = false;
        this.refreshing = false;
      } catch {
        this.loading = false;
        this.finished = true;
        this.refreshing = false;
      }
    },

    cancelled() {
      this.$router.push('/cancelled')
    },
  },
  created() {
    ChangePageTitle("已核销订单");
  },
};
</script>

<style lang="scss" scoped>
.content {
  min-height: 100vh;
  background-color: #f7f7f7;
}

.display-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-search {
  width: 375px;
  background: #FFFFFF;
  z-index: 9;
  position: sticky;
  top: 0;

  .van-search__content {
    width: 262px;
    height: 34px;
    background: #FFFFFF;
    border-radius: 22px;
    border: 1px solid #CCCCCC;
  }
}

.search {
  width: 343px;
  margin: auto;
  margin-top: 12px;
  padding: 16px;
  font-size: 14px;
  color: #666666;
  background-color: #FFFFFF;
  border-radius: 8px;
  box-sizing: border-box;

  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .iconfont {
      font-size: 12px;

      &::before {
        transform-origin: center;
        transform: rotate(90deg);
      }
    }
  }

  .name {
    width: 80px;
    height: 30px;
    background: #F5F8FA;
    border-radius: 15px;
    text-align: center;
    line-height: 30px;
  }

  .time {
    width: 209px;
    height: 30px;
    background: #F5F8FA;
    border-radius: 15px;
    text-align: center;
    line-height: 30px;
  }

  .sales {
    text-align: center;
    font-size: 14px;
    color: #999999;

    .value {
      margin-top: 22px;
      font-size: 24px;
      color: #333333;
      font-weight: 500;
    }
  }
}

.list {
  width: 343px;
  margin: auto;
  margin-top: 12px;

  .van-pull-refresh{
    min-height: 65vh;
  }

  .item {
    width: 100%;
    background: #FFFFFF;
    border-radius: 6px;
    padding: 16px;
    box-sizing: border-box;
    font-size: 14px;
    color: #999999;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .name {
        width: 112px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 13px;
        font-weight: 500;
        font-size: 16px;
        color: #333333;
      }

      .quantity {
        font-size: 14px;
        color: #333333;
      }
    }

    .time {
      margin-top: 12px;
    }

    .code {
      margin-top: 4px;
    }
  }
}

.fixed-btn {
  position: fixed;
  bottom: 0;
  background-color: #FFFFFF;
  width: 100%;
  padding: 8px 16px;
  border-top: 1px solid #F7F7F7;

  .btn {
    width: 343px;
    height: 42px;
    background-color: #F86D0C;
    color: #FFFFFF;
  }
}

.popup {
  ::v-deep .van-picker__cancel {
    font-size: 14px;
    color: #999999;
  }

  ::v-deep .van-picker__confirm {
    font-size: 14px;
    color: #F86D0C;
  }
}

.popup-time {
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 18px 16px;
    font-size: 14px;

    .cancel {
      color: #999999;
    }

    .confirm {
      color: #F86D0C;
    }
  }

  .time-range {
    text-align: center;
    font-size: 14px;
    color: #333333;
    padding: 25px 44px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

    .time {
      font-size: 16px;
      color: #333333;
      font-weight: 500;
      width: 94px;
      text-align: center;
      vertical-align: bottom;
    }

    .active {
      color: #F86D0C;
    }
  }

  .tip {
    width: 100%;
    height: 36px;
    font-size: 14px;
    text-align: center;
    line-height: 36px;
    background: #FFF8EA;
    color: #F86D0C;
  }

  &::v-deep .van-picker-column__item--selected {
    color: #323233;
    font-weight: 500;
    font-size: 18px;
  }
}

.popup-phone {
  &::v-deep .van-popup--round {
    border-radius: 8px;
  }

  &::v-deep .van-popup__close-icon--top-right {
    font-size: 16px;
    font-weight: 600;
    color: #999999;
  }

  .phone-box {
    width: 294px;
    background: #FFFFFF;
    text-align: center;
    font-size: 14px;
    color: #333333;
    font-weight: 500;
    padding: 0 16px 16px;

    img {
      width: 46px;
      height: 46px;
      margin-top: 39px;
    }

    .input {
      margin-top: 24px;
      width: 100%;
      height: 48px;
      background: #FFF6F2;
      border-radius: 24px;
      font-size: 14px;
      color: #333333;
      width: 100%;
      background-color: #FFF6F2;

      ::v-deep .van-field__label {
        // width: 42px;
        color: #333333;
        font-size: 14px;
        display: flex;
        align-items: center;
        font-weight: 400;

      }

      ::v-deep .van-field__value {
        display: flex;
        align-items: center;
        justify-content: end;
        flex: 1;
      }

      .van-cell::after {
        border: 0;
      }

      ::v-deep input::placeholder {
        color: #999;
        font-weight: 400;
      }
    }

    .export-btn {
      width: 120px;
      height: 40px;
      margin: auto;
      margin-top: 24px;
      background: #F86D0C;
      border-radius: 29px;
      color: #FFFFFF;

    }
  }
}
</style>
